import {createRouter, createWebHashHistory} from 'vue-router'
import {getSystemConfigInfo} from '@/assets/js/login.js'

const router = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            redirect: '/videoTabHome'
        },
        {
            path: '/videoTabHome',
            name: 'videoTabHome',
            component: () => import('../views/video/videoHome/videoTabHome.vue')
        },
        {
            path: '/video3DTabHome',
            name: 'video3DTabHome',
            component: () => import('../views/video/video3DHome/video3DTabHome.vue')
        },
        /*兼容老版本开奖直播页面路径*/
        {
            path: '/kjLivePage',
            redirect: '/kjLiveHome'
        },
        {
            path: '/kjLiveHome',
            name: 'kjLiveHome',
            component: () => import('../views/found/kjLive/kjLiveHome.vue')
        },
        {
            path: '/videoHomePosters',
            name: 'videoHomePosters',
            component: () => import('../views/my/videoHomePosters.vue')
        },
        {
            path: '/zzfwProtocolBook',
            name: 'zzfwProtocolBook',
            component: () => import('../views/readmeText/zzfwProtocolBook.vue')
        },
        /*兼容老版本我的设置页面路径*/
        {
            path: '/videoSetPage',
            redirect: '/setting'
        },
        {
            path: '/setting',
            name: 'setting',
            component: () => import('../views/my/setting/setting.vue'),
            meta: {
                title: "我的设置",
                content: '我的设置'
            }
        },
        /*兼容老版本讲师视频页面路径*/
        {
            path: '/myVideos',
            redirect: '/instructorVideoList'
        },
        {
            path: '/instructorVideoList',
            name: 'instructorVideoList',
            component: () => import('../views/instructor/instructorVideo/instructorVideoList/instructorVideoList.vue'),
            beforeEnter: (to, from) => {
                if (from.name === 'instructorVacate' || from.name === 'instructorVideoReleaseTime') {
                    to.meta.initInstructorInfo = true;
                } else if (from.name === 'instructorVideoSoldOut' && from.meta.applyVideoSoldOutSuccess) {
                    to.meta.applyVideoSoldOutSuccess = true;
                } else if (from.name === 'instructorUpLoadWinning' && from.meta.saveVideoWinningSuccess) {
                    to.meta.saveVideoWinningSuccess = true;
                } else if (from.name === 'instructorReleaseVideo' && from.meta.saveVideoSuccess) {
                    to.meta.saveVideoSuccess = true;
                } else if (from.name === 'instructorChangeVideo' && from.meta.saveChangeVideoSuccess) {
                    to.meta.saveChangeVideoSuccess = true;
                }
                return true;
            }
        },
        {
            path: '/iosDownloadGuide',
            name: 'iosDownloadGuide',
            component: () => import('../views/downloadGuide/iosDownloadGuide.vue')
        },
        /*兼容老版本讲师发布视频页面路径*/
        {
            path: '/videoReleaseHome',
            redirect: '/instructorReleaseVideo'
        },
        {
            path: '/instructorReleaseVideo',
            name: 'instructorReleaseVideo',
            component: () => import('../views/instructor/instructorVideo/instructorReleaseVideo/instructorReleaseVideo.vue'),
            beforeEnter: (to, from) => {
                if (from.name === 'setVideoMoney' && from.meta.setSuccess) {
                    to.meta.videoIndex = from.meta.videoIndex;
                    to.meta.videoMoney = from.meta.videoMoney;
                }
                return true;
            }
        },
        {
            path: '/videoItemEarnings',
            name: 'videoItemEarnings',
            component: () => import('../views/my/master/videoItemEarnings.vue')
        },
        /*兼容老版本我的设置页面路径*/
        {
            path: '/videoUserRecord',
            redirect: '/instructorRecord'
        },
        {
            path: '/instructorRecord',
            name: 'instructorRecord',
            component: () => import('../views/instructor/instructorRecord/instructorRecord.vue')
        },
        /*兼容老版本讲师变更视频页面路径*/
        {
            path: '/changeVideo',
            redirect: '/instructorChangeVideo'
        },
        {
            path: '/instructorChangeVideo',
            name: 'instructorChangeVideo',
            component: () => import('../views/instructor/instructorVideo/instructorChangeVideo.vue')
        },
        {
            path: '/buyVideoHome',
            name: 'buyVideoHome',
            component: () => import('@/views/pay/buyVideoHome.vue')
        },
        {
            path: '/jsxyProtocolBook',
            name: 'jsxyProtocolBook',
            component: () => import('../views/my/master/book/jsxyProtocolBook.vue')
        },
        {
            path: '/tghbxyProtocolBook',
            name: 'tghbxyProtocolBook',
            component: () => import('../views/my/agency/tghbxyProtocolBook.vue')
        },
        {
            path: '/tgjhProtocolBook',
            name: 'tgjhProtocolBook',
            component: () => import('../views/my/agency/tgjhProtocolBook.vue')
        },
        {
            path: '/czProtocolBook',
            name: 'czProtocolBook',
            component: () => import('../views/readmeText/czProtocolBook.vue')
        },
        {
            path: '/instructorUpLoadWinning',
            name: 'instructorUpLoadWinning',
            component: () => import('../views/instructor/instructorVideo/instructorUpLoadWinning/instructorUpLoadWinning.vue')
        },
        {
            path: '/releaseVideoCourse',
            name: 'releaseVideoCourse',
            component: () => import('../views/readmeText/releaseVideoCourse.vue')
        },
        {
            path: '/fcsjProtocolBook',
            name: 'fcsjProtocolBook',
            component: () => import('../views/my/master/book/fcsjProtocolBook.vue')
        },
        {
            path: '/attentionServiceNumber',
            name: 'attentionServiceNumber',
            component: () => import('../views/projectPage/attentionServiceNumber.vue')
        },
        {
            path: '/znhg',
            name: 'znhgPage',
            component: () => import('../views/found/znhgPage.vue')
        },
        {
            path: '/gljbPage',
            name: 'gljbPage',
            component: () => import('../views/found/gljbPage.vue')
        },
        {
            path: '/unTrustGljb',
            name: 'unTrustGljbPage',
            component: () => import('../views/found/unTrustGljbPage.vue'),
            meta: {
                title: "鹧鸪奖表",
                content: '鹧鸪奖表'
            }
        },
        {
            path: '/jskhzbProtocolBook',
            name: 'jskhzbProtocolBook',
            component: () => import('../views/my/master/book/jskhzbProtocolBook.vue')
        },
        {
            path: '/myInvitationCode',
            name: 'myInvitationCode',
            component: () => import('../views/my/myInvitationCode.vue')
        },
        {
            path: '/videoToReportWork',
            name: 'videoToReportWork',
            component: () => import('../views/instructor/videoToReportWork.vue')
        },
        {
            path: '/videoMasterEarnings',
            name: 'videoMasterEarnings',
            component: () => import('../views/my/master/videoMasterEarnings.vue')
        },
        /*兼容老版本讲师请假页面路径*/
        {
            path: '/videoMasterVacate',
            redirect: '/instructorVacate'
        },
        {
            path: '/instructorVacate',
            name: 'instructorVacate',
            component: () => import('../views/instructor/instructorVacate.vue')
        },
        /*兼容老版本讲师预告发布视频时间页面路径*/
        {
            path: '/auditForeTime',
            redirect: '/instructorVideoReleaseTime'
        },
        {
            path: '/instructorVideoReleaseTime',
            name: 'instructorVideoReleaseTime',
            component: () => import('../views/instructor/instructorVideoReleaseTime.vue')
        },
        {
            path: '/videoAgencyEarnings',
            name: 'videoAgencyEarnings',
            component: () => import('../views/my/agency/videoAgencyEarnings.vue')
        },
        {
            path: '/videoMyGoldHome',
            name: 'videoMyGoldHome',
            component: () => import('../views/my/userAssets/videoMyGoldHome.vue')
        },
        /*兼容老版本金牌讲师页面路径*/
        {
            path: '/videoMastreTeamCentre',
            redirect: '/instructorMastreTeam'
        },
        {
            path: '/instructorMastreTeam',
            name: 'instructorMastreTeam',
            component: () => import('../views/instructor/instructorMastreTeam.vue')
        },
        {
            path: '/browsingHistory',
            name: 'browsingHistory',
            component: () => import('../views/my/browHistory/browsingHistory.vue')
        },
        {
            path: '/videoBecomeMaster',
            name: 'videoBecomeMaster',
            component: () => import('../views/my/master/applyInstructor/videoBecomeMaster.vue'),
            meta: {
                title: "编辑讲师资料",
                content: '编辑讲师资料'
            }
        },
        {
            path: '/videoMasterCentre',
            name: 'videoMasterCentre',
            component: () => import('../views/my/master/videoMasterCentre.vue')
        },
        {
            path: '/videoMasterDaily',
            name: 'videoMasterDaily',
            component: () => import('../views/my/master/videoMasterDaily.vue')
        },
        {
            path: '/videoMasterMonthly',
            name: 'videoMasterMonthly',
            component: () => import('../views/my/master/videoMasterMonthly.vue')
        },
        {
            path: '/videoAgencyCentre',
            name: 'videoAgencyCentre',
            component: () => import('../views/my/agency/videoAgencyCentre.vue')
        },
        {
            path: '/masterDetailOfEarnings',
            name: 'masterDetailOfEarnings',
            component: () => import('../views/my/master/masterDetailOfEarnings.vue')
        },
        /*兼容老版本结算佣金页面路径*/
        {
            path: '/agencyDetailOfEarnings',
            redirect: '/agencyDetails'
        },
        {
            path: '/agencyDetails',
            name: 'agencyDetails',
            component: () => import('../views/my/agency/agencyDetails.vue')
        },
        {
            path: '/videoMasterApplicationResult',
            name: 'videoMasterApplicationResult',
            component: () => import('../views/my/master/videoMasterApplicationResult.vue')
        },
        {
            path: '/videoMyCoupons',
            name: 'videoMyCoupons',
            component: () => import('../views/my/userAssets/videoMyCoupons.vue')
        },
        /*兼容老版本我的消费页面路径*/
        {
            path: '/videoMyConsumption',
            redirect: '/myConsumption'
        },
        {
            path: '/myConsumption',
            name: 'myConsumption',
            component: () => import('../views/my/consume/myConsumption.vue')
        },
        {
            path: '/videoMyOrder',
            name: 'videoMyOrder',
            component: () => import('../views/my/myOrder/videoMyOrder.vue'),
            beforeEnter: (to, from) => {
                if (from.name === 'videoOrderDetaills' && from.meta.cancelOrderSuccess) {
                    to.meta.cancelOrderSuccess = from.meta.cancelOrderSuccess;
                } else if ((from.name === 'buyVideoHome' || from.name === 'videoOrderDetaills') && from.meta.buyVideoSuccessFlag) {
                    to.meta.buyVideoSuccessFlag = from.meta.buyVideoSuccessFlag;
                } else if ((from.name === 'videoVipHome' || from.name === 'videoOrderDetaills') && from.meta.buyVipSuccessFlag) {
                    to.meta.buyVipSuccessFlag = from.meta.buyVipSuccessFlag;
                }
                return true;
            }
        },
        {
            path: '/iosRechargeItems',
            name: 'iosRechargeItems',
            component: () => import('../views/my/iosRechargeItems.vue'),
            meta: {
                title: "开通会员、充值服务入口",
                content: '欢迎使用鹧鸪讲堂，多谢支持！推荐使用app或者关注公众号更方便'
            }
        },
        {
            path: '/videoOrderDetaills',
            name: 'videoOrderDetaills',
            component: () => import('../views/my/myOrder/videoOrderDetaills.vue'),
            beforeEnter: (to, from) => {
                if (from.name === 'buyVideoHome' && from.meta.buyVideoSuccessFlag) {
                    to.meta.buyVideoSuccessFlag = from.meta.buyVideoSuccessFlag;
                } else if (from.name === 'videoVipHome' && from.meta.buyVipSuccessFlag) {
                    to.meta.buyVipSuccessFlag = from.meta.buyVipSuccessFlag;
                }
                return true;
            }
        },
        {
            path: '/videoMyEarningsHome',
            name: 'videoMyEarningsHome',
            component: () => import('../views/my/userAssets/videoMyEarningsHome.vue')
        },
        /*兼容老版本我的页面路径*/
        {
            path: '/videoMyHome',
            redirect: '/my'
        },
        {
            path: '/my',
            name: 'my',
            component: () => import('../views/my/my/my.vue')
        },
        {
            path: '/myInvitationCourse',
            name: 'myInvitationCourse',
            component: () => import('../views/my/myInvitationCourse.vue')
        },
        {
            path: '/myInvitationCourseApp',
            name: 'myInvitationCourseApp',
            component: () => import('../views/my/myInvitationCourseApp.vue')
        },
        {
            path: '/videoNoticeHome',
            name: 'videoNoticeHome',
            component: () => import('../views/my/announcement/videoNoticeHome.vue')
        },
        {
            path: '/videoMyMessage',
            name: 'videoMyMessage',
            component: () => import('../views/my/myMessage/videoMyMessage.vue')
        },
        {
            path: '/videoEditProfileHome',
            name: 'videoEditProfileHome',
            component: () => import('../views/my/setting/videoEditProfileHome.vue'),
            meta: {
                title: "编辑个人资料",
                content: '编辑个人资料'
            }
        },
        /*兼容老版本关注-粉丝页路径*/
        {
            path: '/videoUserSocialGraph',
            redirect: '/followFans'
        },
        {
            path: '/followFans',
            name: 'followFans',
            component: () => import('../views/my/followFans/followFans.vue')
        },
        {
            path: '/videoNoticeDetails',
            name: 'videoNoticeDetails',
            component: () => import('../views/my/announcement/videoNoticeDetails.vue')
        },
        /*兼容老版本视频详情页面路径*/
        {
            path: '/videoTabdetails1',
            redirect: '/videoDetails'
        },
        {
            path: '/videoDetails',
            name: 'videoDetails',
            component: () => import('../views/video/videoDetails/videoDetails.vue'),
            beforeEnter: (to, from) => {
                if (from.name === 'buyVideoHome' && from.meta.buyVideoSuccessFlag) {
                    to.meta.buyVideoSuccessFlag = from.meta.buyVideoSuccessFlag;
                }
                return true;
            }
        },
        /*兼容老版本讲师主页路径*/
        {
            path: '/videoUserHomePage',
            redirect: '/instructorHome'
        },
        {
            path: '/instructorHome',
            name: 'instructorHome',
            component: () => import('../views/instructor/instructorHome/instructorHome.vue')
        },
        /*兼容老版本讲师列表页路径*/
        {
            path: '/videoInstructorList',
            redirect: '/instructorList'
        },
        {
            path: '/instructorList',
            name: 'instructorList',
            component: () => import('../views/instructor/instructorList/instructorList.vue')
        },
        /*兼容老版本发现页路径*/
        {
            path: '/videoFoundPage',
            redirect: '/found'
        },
        {
            path: '/found',
            name: 'foundPage',
            component: () => import('../views/found/foundPage.vue')
        },
        {
            path: '/predictedList',
            name: 'predictedList',
            component: () => import('../views/predictedList/predictedList.vue')
        },
        {
            path: '/goldCoinsTopUp',
            name: 'goldCoinsTopUp',
            component: () => import('@/views/pay/goldCoinsTopUp.vue')
        },
        {
            path: '/rewardUser',
            name: 'rewardUser',
            component: () => import('@/views/pay/rewardUser.vue')
        },
        {
            path: '/setVideoMoney',
            name: 'setVideoMoney',
            component: () => import('../views/instructor/instructorVideo/instructorReleaseVideo/setVideoMoney.vue')
        },
        {
            path: '/myBillDetails',
            name: 'myBillDetails',
            component: () => import('../views/my/userAssets/myBill/myBillDetails.vue')
        },
        {
            path: '/myBillHome',
            name: 'myBillHome',
            component: () => import('../views/my/userAssets/myBill/myBillHome.vue')
        },
        {
            path: '/couponUseState',
            name: 'couponUseState',
            component: () => import('../views/readmeText/couponUseState.vue')
        },
        {
            path: '/wheelSurf',
            name: 'wheelSurf',
            component: () => import('../views/wheelSurf/wheelSurf.vue')
        },
        {
            path: '/contactCustomerService',
            name: 'contactCustomerService',
            component: () => import('../views/my/service/contactCustomerService.vue')
        },
        {
            path: '/goldCoinState',
            name: 'goldCoinState',
            component: () => import('../views/readmeText/goldCoinState.vue')
        },
        /*兼容老版本讲师申请视频下架页路径*/
        {
            path: '/lowerVideoReason',
            redirect: '/instructorVideoSoldOut'
        },
        {
            path: '/instructorVideoSoldOut',
            name: 'instructorVideoSoldOut',
            component: () => import('../views/instructor/instructorVideoSoldOut.vue')
        },
        /*兼容老版本绑定手机号页路径*/
        {
            path: '/userBindPhoneNumber',
            redirect: '/bindPhoneNumber'
        },
        {
            path: '/bindPhoneNumber',
            name: 'bindPhoneNumber',
            component: () => import('../views/login/userBindPhoneNumber.vue')
        },
        {
            path: '/videoHomeLogin',
            name: 'videoHomeLogin',
            component: () => import('../views/login/videoHomeLogin.vue')
        },
        /*兼容老版本找回密码页路径*/
        {
            path: '/videoRetrievePassword',
            redirect: '/retrievePassWord'
        },
        {
            path: '/retrievePassWord',
            name: 'retrievePassWord',
            component: () => import('../views/login/retrievePassWord.vue')
        },
        {
            path: '/videoUpdatePassword',
            name: 'videoUpdatePassword',
            component: () => import('../views/my/setting/videoUpdatePassword.vue'),
            meta: {
                title: "修改密码",
                content: '修改密码'
            }
        },
        /*兼容老版本合并用户页路径*/
        {
            path: '/videoConsolidatedAccounts',
            redirect: '/userInfoMerge'
        },
        {
            path: '/userInfoMerge',
            name: 'userInfoMerge',
            component: () => import('../views/login/userInfoMerge.vue')
        },
        {
            path: '/payOrderPage',
            name: 'payOrderPage',
            component: () => import('@/views/pay/payOrderPage.vue')
        },
        {
            path: '/videoVipHome',
            name: 'videoVipHome',
            component: () => import('../views/my/videoVip/videoVipHome/videoVipHome.vue'),
            beforeEnter: (to, from) => {
                if (from.name === 'buyVideoVip' && from.meta.buyVipSuccessFlag) {
                    to.meta.buyVipSuccessFlag = from.meta.buyVipSuccessFlag;
                }
                return true;
            }
        },
        {
            path: '/buyVideoVip',
            name: 'buyVideoVip',
            component: () => import('@/views/pay/buyVideoVip.vue')
        },
        {
            path: '/videoVipRecord',
            name: 'videoVipRecord',
            component: () => import('../views/my/videoVip/videoVipRecord/videoVipRecord.vue')
        },
        {
            path: '/cjActivityRules',
            name: 'cjActivityRules',
            component: () => import('../views/ruleExplain/cjActivityRules.vue')
        },
        {
            path: '/myPrize',
            name: 'myPrize',
            component: () => import('../views/wheelSurf/myPrize.vue')
        },
        {
            path: '/giveFriend',
            name: 'giveFriend',
            component: () => import('../views/video/giveFriend/giveFriend.vue')
        },
        {
            path: '/wechatAuthLogin',
            name: 'wechatAuthLogin',
            component: () => import('../views/login/wechatAuthLogin.vue')
        }
    ]
})
/* 全局路由守卫 */
router.beforeEach((to, from, next) => {
    if (!from || from.fullPath === "/") {
        getSystemConfigInfo(to, next);
    } else {
        next();
    }
})

export default router
