import globalConstant from '@/assets/js/globalConstant.js';
import axios from 'axios';
import {showFailToast} from 'vant';
import {useUserInfoStore} from '@/stores/userInfo.js';
import {userUnusableDialog} from '@/assets/js/commonFunction.js'
import {ref} from "vue";
import {stringContainArrayValue} from "@/assets/js/commonUtil.js";

function getUserInfoStore() {
    return useUserInfoStore();
}

/* 创建一个 axios 实例 */
const service = axios.create({
    timeout: 7000,
    headers: {
        Accept: 'application/json',
        post: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }
})

/* token是否处于更新中 */
const tokenUpdateing = ref(false);
/* 更新token时请求的队列 */
const httpRequestList = ref([]);

/* 请求拦截器 */
service.interceptors.request.use(config => {
    if (config.url.indexOf(globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME) > -1) {
        /* 判断是否已登录，是则带上accesstoken */
        if (getUserInfoStore().checkUserLoginState() && !stringContainArrayValue(config.url, ['web/user/token'])) {
            config.headers.accesstoken = getUserInfoStore().userInfo.accessToken;
        }
    }
    return config
}, error => {
    // return error
    Promise.reject(error);
})

/* 响应拦截器 */
service.interceptors.response.use(response => {
    const err_code = response.data.err_code;
    if (err_code === 50005) {
        // token过期
        const requestConfig = response.config;
        if (!tokenUpdateing.value) {
            //修改状态，进入更新token阶段
            tokenUpdateing.value = true
            // 获取当前的请求
            getUserInfoStore().updateAccessToken().then((res) => {
                if (res) {
                    //token更新完成 ——> 重新发起请求
                    httpRequestList.value.forEach(it => it());
                    //清空请求队列
                    httpRequestList.value = [];
                    //关闭token更新
                    tokenUpdateing.value = false;
                } else {
                    //重新请求token失败，跳转到登录页
                    userUnusableDialog();
                }
            }).catch((res) => {
                //重新请求token失败，跳转到登录页
                userUnusableDialog();
            })
        }
        // 更新token中，先将请求配置push到reqLists，token更新完成后再重新发起请求
        return new Promise((resolve) => {
            httpRequestList.value.push(() => {
                resolve(service(requestConfig));
            });
        })
    } else if (err_code === 50006) {
        //token为空
        userUnusableDialog();
    } else if (!err_code || err_code === 200) {
        //将执行.then()
        return Promise.resolve(response);
    } else if (err_code === 90005 && response.config.url.indexOf("web/user/checkPhoneVerifiCode") > -1) {
        //将执行.catch()
        return Promise.reject(response);
    } else {
        const message = ref('');
        if (response.data.err_msg !== "") {
            message.value = response.data.err_msg;
        } else {
            message.value = '系统繁忙，请稍后再试';
        }
        showFailToast(message.value);
        //将执行.catch()
        return Promise.reject(response);
    }
}, error => {
    const requestUrl = error.config.url;
    if (requestUrl.indexOf(globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME) > -1) {
        // 再次发送请求
        error.config.url = requestUrl.replace(globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME, globalConstant.CHUKAR_SERVER_ACCESS_STANDBY_DOMAIN_NAME);
        const backoff = new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, 10);
        });

        return backoff.then(() => {
            return service(error.config);
        });
    } else {
        showFailToast('系统繁忙，请稍后再试');
        //将执行.catch()
        return Promise.reject(error);
    }
})
export default service
