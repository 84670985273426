import {ref} from 'vue'
import {defineStore} from 'pinia'
import {setLocalStorage, removeLocalStorage, getLocalStorage} from '@/assets/js/commonUtil.js'
import httpRequest from '@/httpRequest/httpRequest.js'
import globalConstant from '@/assets/js/globalConstant.js'
import {useCommonInfoStore} from '@/stores/commonInfoStore.js';
import {useRabbitMqStore} from "@/stores/rabbitMq.js";

const getCommonInfoStore = () => {
    return useCommonInfoStore();
}

const getRabbitMqStore = () => {
    return useRabbitMqStore();
}

export const useUserInfoStore = defineStore(
    'userInfo',
    () => {
        const userInfo = ref({
            userId: '',
            accessToken: '',
            userBasicData: {
                bigWordVersion: 0
            }
        });
        /* 赋值用户ID */
        const setUserIdValue = (value) => {
            userInfo.value.userId = value;
        };
        /* 赋值用户accessToken */
        const setAccessTokenValue = (value) => {
            userInfo.value.accessToken = value;
        };
        /* 赋值用户基本信息 */
        const setUserBasicDataValue = (value) => {
            userInfo.value.userBasicData = value;
        };
        /* 赋值用户基本信息 */
        const setUserBigWordVersion = (value) => {
            userInfo.value.userBasicData.bigWordVersion = value;
        };
        /* 清除用户数据 */
        const clearUserInfo = () => {
            userInfo.value = {
                userId: '',
                accessToken: '',
                userBasicData: {
                    bigWordVersion: 0
                }
            };
            removeLocalStorage('userInfo');
        };
        const initUserLoginState = (userId, accessToken) => {
            clearUserInfo();
            setLocalStorage(globalConstant.CHUKAR_WEB_MOBILE_VERSION, getCommonInfoStore().systemConfigInfo.systemOnlineVersion);
            setUserIdValue(userId);
            setAccessTokenValue(accessToken);
        }
        /* 检查用户是否登录 */
        const checkUserLoginState = () => {
            const version = ref('');
            if (getCommonInfoStore().accessSource === "wxPublic") {
                version.value = getLocalStorage(globalConstant.CHUKAR_WEB_MOBILE_VERSION, globalConstant.THREE_DAYS_MILLISECONDS);
            } else if (getCommonInfoStore().accessSource === "webH5") {
                if (navigator.standalone) {
                    //iOS中浏览器直接访问站点时，navigator.standalone为false,从主屏启动webapp 时，navigator.standalone为true
                    version.value = getLocalStorage(globalConstant.CHUKAR_WEB_MOBILE_VERSION);
                } else {
                    version.value = getLocalStorage(globalConstant.CHUKAR_WEB_MOBILE_VERSION, globalConstant.TWO_HOURS_MILLISECONDS);
                }
            } else {
                version.value = getLocalStorage(globalConstant.CHUKAR_WEB_MOBILE_VERSION);
            }

            return (version.value && version.value !== '' && version.value === getCommonInfoStore().systemConfigInfo.systemOnlineVersion &&
                userInfo.value.accessToken && userInfo.value.accessToken !== '' &&
                userInfo.value.userId && userInfo.value.userId !== '')
        };
        /* 更新用户accessToken */
        const updateAccessToken = async () => {
            const updateFlag = ref(false);
            const source = ref('');
            if (getCommonInfoStore().accessSource === 'webH5') {
                source.value = "web_phone_user";
            } else if (getCommonInfoStore().accessSource === 'wxPublic') {
                source.value = "weixin_xggzs_public_number";
            } else if (getCommonInfoStore().accessSource === 'wxAppLet') {
                source.value = getCommonInfoStore().accessSourceClient;
            }
            const response = await httpRequest({
                method: 'POST',
                url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "web/user/token",
                params: {userId: userInfo.value.userId, source: source.value, sourceUserId: getCommonInfoStore().shareUserId}
            })
            const accessTokenJson = JSON.parse(response.data.data);
            if (accessTokenJson.access_token) {
                setAccessTokenValue(accessTokenJson.access_token);
                updateFlag.value = true;
            }
            return updateFlag.value;
        };
        /* 获取用户基本信息 */
        const getUserInfo = () => {
            httpRequest({
                method: 'GET',
                url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "web/user/getUserInfo",
                params: {appId: getCommonInfoStore().systemConfigInfo.wechatAuthLoginPublicAppId}
            }).then((response) => {
                const userJson = JSON.parse(response.data.data);
                setUserBasicDataValue(userJson);
                if (userInfo.value.userBasicData.messageNotify) {
                    setTimeout(() => {
                        userInfo.value.userBasicData.messageNotify = false;
                    }, 5000)
                }
                //连接RabbitMq
                getRabbitMqStore().connectToRabbitMq();
            });
        };

        return {
            userInfo,
            setUserIdValue,
            setAccessTokenValue,
            setUserBasicDataValue,
            setUserBigWordVersion,
            clearUserInfo,
            initUserLoginState,
            checkUserLoginState,
            updateAccessToken,
            getUserInfo
        }
    },
    {
        persist: true
    }
)
