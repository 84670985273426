<script setup>
import {useUserInfoStore} from "@/stores/userInfo.js";
import {routerMyMessage} from "@/assets/js/commonFunction.js";

const userInfoStore = useUserInfoStore();
</script>

<template>
  <van-notify v-model:show="userInfoStore.userInfo.userBasicData.messageNotify" type="success" @click="routerMyMessage()">
    <van-icon name="bell" style="margin-right: 10px;" />
    <span style="text-align: justify">{{ userInfoStore.userInfo.userBasicData.message }}</span>
  </van-notify>
</template>

<style scoped>

</style>