<script setup>
import globalConstant from "@/assets/js/globalConstant.js";
import {useUserInfoStore} from "@/stores/userInfo.js";
import {useCommonInfoStore} from "@/stores/commonInfoStore.js";
import {ref} from "vue";
import {useRoute} from "vue-router";
import {checkInStringArray, getLocalStorage, setLocalStorage} from "@/assets/js/commonUtil.js";
import {useOpenChukarVideoApp} from "@/assets/js/commonComposables.js";
import {routerBindPhoneNumber} from "@/assets/js/commonFunction.js";

const userInfoStore = useUserInfoStore();
const commonInfoStore = useCommonInfoStore();
const route = useRoute();
const znhgCourseImg = globalConstant.CHUKAR_STATIC_FILE_ACCESS_URL + 'static/images/system_tz.png';
const downAppImg = globalConstant.CHUKAR_STATIC_FILE_ACCESS_URL + 'static/images/zheguQrCode.png';

const showSystemMaintainPopup = ref(false);
//六小时内不再弹框提示
const systemMaintainPopupVisible = getLocalStorage("systemMaintainPopupVisible", globalConstant.SIX_HOURS_MILLISECONDS);
if (!systemMaintainPopupVisible && userInfoStore.checkUserLoginState() && (commonInfoStore.accessSource === 'wxPublic' || (commonInfoStore.accessSource === 'wxAppLet' && checkInStringArray(route.path, ["/unTrustGljb", "/setting", "/contactCustomerService", "/kjLiveHome"])))) {
  showSystemMaintainPopup.value = true;
  setLocalStorage("systemMaintainPopupVisible", showSystemMaintainPopup.value);
}

const downLoadNow = () => {
  showSystemMaintainPopup.value = false;
  useOpenChukarVideoApp().downLoadApp();
}
</script>

<template>
  <div class="systemMaintenancePopup">
    <van-popup v-model:show="showSystemMaintainPopup">
      <div class="systemMaintenancePopupCt">
        <div class="systemMaintenancePopupTitle">
          <div class="systemMaintenancTitleNb">
            <van-image width="60%" :src="znhgCourseImg" />
          </div>
        </div>
        <div class="systemMaintenancePopupState">
          重要通知
        </div>
        <div v-if="userInfoStore.userInfo.userBasicData.phoneNumber && userInfoStore.userInfo.userBasicData.phoneNumber !== ''">
          <div class="systemMaintenancePopupContent">
            因微信管理规范变动，可能导致无法访问网站，强烈推荐下载app访问。
          </div>
          <div class="systemMaintenancePopupImg" v-if="commonInfoStore.accessSource === 'wxAppLet'">
            <div class="systemMaintenancePopupImgNb">
              <van-image :src="downAppImg" />
              <span class="systemMaintenanceSpan">扫描二维码下载app</span>
            </div>
          </div>
          <div class="systemMaintenancePopupImg" v-else>
            <div class="ljDown" @click="downLoadNow">立即下载</div>
          </div>
        </div>
        <div v-else>
          <div class="systemMaintenancePopupContent">
            因微信管理规范变动，强烈推荐绑定个人手机号码，以免影响后续无法访问网站。
          </div>
          <div class="systemMaintenancePopupImg">
            <div class="ljDown" @click="routerBindPhoneNumber()">立即绑定</div>
          </div>
        </div>
        <div class="popUp_cancel_content1">
          <div class="popUp_cancel_button" @click="showSystemMaintainPopup = false">X</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<style scoped>
.systemMaintenancePopup .van-popup {
  overflow-y: inherit;
}

.systemMaintenancePopupCt {
  width: 90%;
  position: relative;
  border-radius: 5px;
  background-color: #fff;
  margin: auto;
}

.popUp_cancel_content1 {
  position: absolute;
  left: 0;
  bottom: -90px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popUp_cancel_button {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  font-size: 20px;
  border: 1px solid #fff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.systemMaintenancTitleNb {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.systemMaintenancePopupState {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 10px;
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  color: #ec292d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ljDown {
  width: 70%;
  height: 35px;
  background: #ec292d;
  color: #ffffff;
  font-size: 16px;
  border-radius: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.systemMaintenancePopupContent {
  width: 90%;
  margin: 0 auto;
  font-size: 16px;
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left;
  line-height: normal;
}

.systemMaintenancePopupImg {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.systemMaintenancePopupImg img {
  width: 130px;
  margin-top: 10px;
}

.systemMaintenanceSpan {
  width: 100%;
  height: 35px;
  font-size: 16px;
  color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
</style>