<script setup>
import globalConstant from "@/assets/js/globalConstant.js";
import {ref, watch} from "vue";
import {useUserInfoStore} from "@/stores/userInfo.js";
import {getLocalStorage, setLocalStorage} from "@/assets/js/commonUtil.js";
import httpRequest from "@/httpRequest/httpRequest.js";
import {showSuccessToast} from "vant";

const newUserRegisterPopupImg = globalConstant.CHUKAR_STATIC_FILE_ACCESS_URL + 'static/images/newUserRegister.png';
const userInfoStore = useUserInfoStore();
const showNewUserRegisterPopup = ref(false);
watch(
    () => userInfoStore.userInfo.userBasicData,
    (newUserBasicData, oldUserBasicData) => {
      //俩个小时内不再弹框
      const newUserReceiveCouponPopup = getLocalStorage("newUserReceiveCouponPopup", globalConstant.TWO_HOURS_MILLISECONDS);
      if (newUserBasicData.claimFlag === 1 && newUserReceiveCouponPopup == null) {
        showNewUserRegisterPopup.value = true;
        setLocalStorage("newUserReceiveCouponPopup", showNewUserRegisterPopup.value);
      }
    }
);

const receiveCoupon = () => {
  httpRequest({
    method: 'GET',
    url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "v2/user/collectCoupon",
    params: {userType: "firstTimeLogin"},
  }).then((response) => {
    showSuccessToast(response.data.message);
    showNewUserRegisterPopup.value = false;
  });
}
</script>

<template>
  <div class="newUserRegisterPopup">
    <van-popup v-model:show="showNewUserRegisterPopup">
      <div class="newUserRegister_imgBg">
        <van-image width="100%" :src="newUserRegisterPopupImg" />
        <div class="newUserRegister_ljcyBt" @click="receiveCoupon">立即领取</div>
      </div>
      <div class="popUp_cancel_content">
        <div class="popUp_cancel_button" @click="showNewUserRegisterPopup = false">X</div>
      </div>
    </van-popup>
  </div>
</template>

<style scoped>
.newUserRegisterPopup .van-popup {
  overflow-y: inherit;
}

.popUp_cancel_content{
  position: absolute;
  left: 0;
  bottom: -80px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popUp_cancel_button{
  width: 50px;
  height: 50px;
  border-radius: 50px;
  font-size: 20px;
  border: 1px solid #fff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newUserRegister_imgBg{
  position: relative;
  width: 84%;
  margin: auto;
}

.newUserRegister_ljcyBt{
  position: absolute;
  border-radius: 20px;
  font-size: 18px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  background: #fff;
  color: #ec292d;
  left: 25%;
  bottom: 40px;
}
</style>