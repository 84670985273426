import {defineStore} from "pinia";
import {ref} from "vue";
import router from '@/router/index';
import {removeLocalStorage} from "@/assets/js/commonUtil.js";
import {useCommonInfoStore} from "@/stores/commonInfoStore.js";
import globalConstant from "@/assets/js/globalConstant.js";
import {routerVideoTabHome} from "@/assets/js/commonFunction.js";

function getCommonInfoStore() {
    return useCommonInfoStore();
}

export const useRouterStore = defineStore(
    'routerHistoryStack',
    () => {
        const routerHistoryStack = ref([]);

        const pushRouterHistoryStack = (routerData) => {
            if (routerData.name) {
                if (routerHistoryStack.value.length > 0) {
                    const routerName = routerHistoryStack.value[routerHistoryStack.value.length - 1];
                    if (routerData.name !== routerName) {
                        routerHistoryStack.value.push(routerData.name);
                    }
                } else {
                    routerHistoryStack.value.push(routerData.name);
                }
            }
        }

        const delRouterHistoryStack = () => {
            if (routerHistoryStack.value.length > 0) {
                routerHistoryStack.value.splice(routerHistoryStack.value.length - 1, 1);
            }
        }

        const clearRouterHistoryStack = () => {
            routerHistoryStack.value = [];
            removeLocalStorage('routerHistoryStack');
        }

        const forward = (routerData) => {
            pushRouterHistoryStack(routerData);
            router.push(routerData);
        }

        const replace = (routerData) => {
            delRouterHistoryStack();
            pushRouterHistoryStack(routerData);
            router.replace(routerData);
        }

        const back = () => {
            try {
                if (getCommonInfoStore().accessSource.indexOf("app") > -1 || getCommonInfoStore().accessSource.indexOf("App") > -1) {
                    if (routerHistoryStack.value.length < 2) {
                        delRouterHistoryStack();
                        webPageCall.back();
                    } else {
                        routerBackCommon();
                    }
                } else {
                    routerBackCommon();
                }
            } catch (e) {
                routerBackCommon();
            }
        }

        const payPageBack = (payWay) => {
            if (payWay === globalConstant.PAY_TYPE.aliPay) {
                history.go(-2);
            } else if (payWay === globalConstant.PAY_TYPE.weChatPay) {
                history.go(-3);
            } else {
                back();
            }
        }

        const routerBackCommon = () => {
            try {
                if (routerHistoryStack.value.length < 2) {
                    delRouterHistoryStack();
                    routerVideoTabHome();
                } else {
                    delRouterHistoryStack();
                    router.back();
                }
            } catch (e) {
                delRouterHistoryStack();
                router.back();
            }
        }

        /*获取当前路由对象*/
        const getCurrentRoute = () => {
            return router.currentRoute.value;
        }

        return {
            routerHistoryStack,
            pushRouterHistoryStack,
            delRouterHistoryStack,
            clearRouterHistoryStack,
            forward,
            replace,
            back,
            payPageBack,
            getCurrentRoute
        }
    },
    {
        persist: {
            storage: sessionStorage
        }
    }
)