import {useUserInfoStore} from '@/stores/userInfo.js';
import {useCommonInfoStore} from '@/stores/commonInfoStore.js';
import {useRouterStore} from '@/stores/router.js';
import {
    getLocalStorage,
    setLocalStorage,
    isWeixinBrowser,
    checkInStringArray,
    stringContainArrayValue, generateRandomVersion
} from '@/assets/js/commonUtil.js';
import {userUnusableDialog} from '@/assets/js/commonFunction.js'
import globalConstant from '@/assets/js/globalConstant.js';
import httpRequest from '@/httpRequest/httpRequest.js';
import cryptoJs from '@/assets/js/cryptoJs.js';
import {showDialog, showFailToast} from 'vant';
import {ref} from "vue";
import wxJsSdk from "weixin-js-sdk";

function getUserInfoStore() {
    return useUserInfoStore();
}

function getCommonInfoStore() {
    return useCommonInfoStore();
}

function getRouterStore() {
    return useRouterStore();
}
/**
 * 获取系统配置表 字段 switch7 配置信息，判断访问域名是否与可信任用户配置域名一样
 * @param to
 * @param next
 */
export function getSystemConfigInfo(to, next) {
    let systemConfigInfo = getLocalStorage("chukarMobileSystemConfigInfo", 600000);
    if (systemConfigInfo) {
        getCommonInfoStore().setSystemConfigInfoValue(systemConfigInfo);
        checkSourceClient(to, next);
    } else {
        httpRequest({
            method: 'GET',
            url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "v2/getWebMobileSystemConfigInfoAes"
        }).then((response) => {
            systemConfigInfo = JSON.parse(cryptoJs.decrypt(response.data.systemConfig));
            setLocalStorage("chukarMobileSystemConfigInfo", systemConfigInfo);
            getCommonInfoStore().setSystemConfigInfoValue(systemConfigInfo);
            checkSourceClient(to, next);
        })
    }
}

/**
 * 检查用户访问客户端
 * @param to
 * @param next
 */
function checkSourceClient(to, next) {
    if (to.path === "/wechatAuthLogin") {
        next();
    } else {
        if (to.query.client && to.query.client !== '') {
            getCommonInfoStore().setAccessSourceValue(to.query.client);
        }
        if (to.query.shareUserId && to.query.shareUserId !== '') {
            getCommonInfoStore().setShareUserIdValue(to.query.shareUserId);
        }

        if (isWeixinBrowser()) {
            //微信环境
            if (getCommonInfoStore().accessSource === "wxAppLet") {
                //小程序环境
                checkChukarMobileSystemConfigInfo(to, next);
            } else {
                //微信浏览器环境
                getCommonInfoStore().setAccessSourceValue("wxPublic");
                if (to.path === "/payOrderPage") {
                    //支付订单页面直接访问，此跳转是应对访问随机域名用户需要支付逻辑 或 智能画规页面会被iframe引用，直接跳过验证逻辑
                    next();
                } else {
                    //检查用户是否登录
                    checkChukarMobileSystemConfigInfo(to, next);
                }
            }
        } else {
            //外部浏览器环境
            getCommonInfoStore().setAccessSourceValue("webH5");
            checkChukarMobileSystemConfigInfo(to, next);
        }
    }
}

/**
 * 验证系统配置表 字段 switch7 配置信息
 * @param to
 * @param next
 */
function checkChukarMobileSystemConfigInfo(to, next) {
    if (getCommonInfoStore().accessSource === "wxPublic") {
        checkWxPublicUserLoginState(to, next);
    } else if (getCommonInfoStore().accessSource === "wxAppLet" && checkInStringArray(window.location.host, [getCommonInfoStore().systemConfigInfo.wechatAppLetAccessDomainName, getCommonInfoStore().systemConfigInfo.testAccessDomainName, '192.168.0.106:8282'])) {
        if (to.query.sourceClient) {
            getCommonInfoStore().setAccessSourceClientValue(to.query.sourceClient);
        }
        userSimulateLoginByUserId(to, next);
    } else if (getCommonInfoStore().accessSource === "webH5") {
        if (checkInStringArray(window.location.host, getCommonInfoStore().systemConfigInfo.wechatAccessDomainNameList) ||
            stringContainArrayValue(window.location.host, getCommonInfoStore().systemConfigInfo.unTrustUserAccessMainDomainList)) {
            window.location.replace("http://zhegu168.cn/");
        } else {
            //checkWebH5UserLoginState(to, next);
            gaolatVideoSimulateLogin(to, next);
        }
    } else if (getCommonInfoStore().accessSource === "videoApp") {
        userSimulateLoginByUserId(to, next);
    } else if (getCommonInfoStore().accessSource === "htgl") {
        userSimulateLoginByUserId(to, next);
    } else if (getCommonInfoStore().accessSource === "videoAppPay") {
        let loginCode = to.query.loginCode;
        if (loginCode && loginCode !== "") {
            userSimulateLoginByLoginCode(loginCode, to, next);
        }
    }
}

/**
 * 检查微信公众号用户访问登录状态
 * @param to
 * @param next
 */
function checkWxPublicUserLoginState(to, next) {
    let loginCode = to.query.loginCode;
    if (loginCode && loginCode !== "") {
        userSimulateLoginByLoginCode(loginCode, to, next);
    } else if (getUserInfoStore().checkUserLoginState()) {
        getUnTrustUserAccessDomain(to, next);
    } else {
        wechatAuthLogin(to);
    }
}

/**
 * 微信授权登录
 */
export function wechatAuthLogin(to) {
    if (window.location.host !== getCommonInfoStore().systemConfigInfo.wechatAuthLoginDomainName1 && window.location.host !== getCommonInfoStore().systemConfigInfo.wechatAuthLoginDomainName2) {
        wechatAuthSwitchLogin(to);
    } else {
        const appId = getCommonInfoStore().systemConfigInfo.wechatAuthLoginPublicAppId;
        // 使用 Object.entries() 遍历对象 的 属性名称 + 属性值 键值对组合
        const entries = Object.entries(to.query);
        const params = ref('');
        entries.forEach(([key, value]) => {
            if (key !== 'wxOpenId' && key !== 'routePath') {
                params.value += '&' + key + '=' + value;
            }
        });
        params.value = '?routePath=' + (to.query.routePath ? to.query.routePath : (to.path === "/videoHomeLogin" ? 'videoTabHome' : to.path.replace("/", ""))) + params.value;

        const local = window.location.protocol + "//" + window.location.host + "/html/wechatAuthLogin.html" + params.value;
        const state = appId + "_" + new Date().getTime();
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appId + "&redirect_uri=" + encodeURIComponent(local) +
            "&response_type=code&scope=snsapi_base&state=" + state + "#wechat_redirect";
    }
}

/**
 * 微信授权切换登录（随机域名）
 */
function wechatAuthSwitchLogin(to) {
    const appId = getCommonInfoStore().systemConfigInfo.wechatAuthLoginPublicAppId;
    // 使用 Object.entries() 遍历对象 的 属性名称 + 属性值 键值对组合
    const entries = Object.entries(to.query);
    const params = ref('');
    entries.forEach(([key, value]) => {
        if (key !== 'wxOpenId' && key !== 'routePath') {
            params.value += '&' + key + '=' + value;
        }
    });
    params.value = '?routePath=' + (to.query.routePath ? to.query.routePath : (to.path === "/videoHomeLogin" ? 'videoTabHome' : to.path.replace("/", ""))) + "&switchDomain=" + window.location.host + params.value;

    const local = window.location.protocol + "//" + getCommonInfoStore().systemConfigInfo.wechatAuthLoginDomainName2 + "/html/wechatAuthSwitchLogin.html" + params.value;
    const state = appId + "_" + new Date().getTime();
    window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appId + "&redirect_uri=" + encodeURIComponent(local) +
        "&response_type=code&scope=snsapi_base&state=" + state + "#wechat_redirect";
}

/**
 * 检查WebH5用户登录状态
 * @param to
 * @param next
 */
function checkWebH5UserLoginState(to, next) {
    if (navigator.standalone) {
        //iOS中浏览器直接访问站点时，navigator.standalone为false,从主屏启动webapp 时，navigator.standalone为true
        allowUserAccessTo(to, next);
        if (getUserInfoStore().checkUserLoginState()) {
            //初始化用户信息
            getUserInfoStore().getUserInfo();
        }
    } else {
        if (getUserInfoStore().checkUserLoginState()) {
            userLoginSuccessInit(to, next);
        } else {
            if (to.path === "/videoHomeLogin" || window.location.host === getCommonInfoStore().systemConfigInfo.gaolatAppAccessVideoDomainName) {
                allowUserAccessTo(to, next);
            } else {
                window.location.replace(window.location.protocol + "//" + window.location.host + "?v=" + generateRandomVersion() + "/#/videoHomeLogin");
            }
        }
    }
}

/**
 * 允许用户进入访问
 * @param to
 * @param next
 */
function allowUserAccessTo(to, next) {
    // 路由发生变化修改页面title
    if (to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = getCommonInfoStore().systemConfigInfo.systemWebTitle;
    }
    getRouterStore().pushRouterHistoryStack(to);
    next();
}

/**
 * 获取用户信任级别、IP所在地区、随机域名、大字版标识等相关信息
 * @param to
 * @param next
 * @param routeLocationHash
 */
export function getUnTrustUserAccessDomain(to, next, routeLocationHash) {
    httpRequest({
        method: 'GET',
        url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "v2/user/getUnTrustUserAccessDomain",
        params: {accessUrl: window.location.href},
    }).then((response) => {
        if (response) {
            let responseData = response.data;
            if (!routeLocationHash) {
                routeLocationHash = window.location.hash;
            }
            //防止用户直接复制登录页面到微信H5中访问，并且用户已有登录缓存
            if (routeLocationHash !== "" && routeLocationHash.indexOf("/videoHomeLogin") > -1) {
                routeLocationHash = routeLocationHash.replace("/videoHomeLogin", "/");
            }
            checkWxPublicUserAccessPermission(to, next, routeLocationHash, responseData);
        }
    });
}

/**
 * 判断微信端用户的访问权限
 * @param to
 * @param next
 * @param routeLocationHash
 * @param responseData
 */
function checkWxPublicUserAccessPermission(to, next, routeLocationHash, responseData) {
    if (parseInt(responseData.instructorType) === 1) {
        //讲师访问固定切换成讲师访问域名
        if (window.location.host === getCommonInfoStore().systemConfigInfo.instructorAccessDomainName) {
            //因为videoHomeLogin页面手机号登录会传递空值的情况
            if (to && next) {
                userLoginSuccessInit(to, next);
            } else {
                let userAccessDomain = window.location.host + "?v=" + new Date().getTime();
                routerRandomDomain(routeLocationHash, userAccessDomain);
            }
        } else {
            routerRandomDomain(routeLocationHash, getCommonInfoStore().systemConfigInfo.instructorAccessDomainName);
        }
    } else {
        if (checkInStringArray(window.location.host, [responseData.userAccessDomain, getCommonInfoStore().systemConfigInfo.testAccessDomainName, "192.168.0.106:8282"])) {
            //如果当前访问的域名就是用户对应的随机域名，则直接访问
            //因为videoHomeLogin页面手机号登录会传递空值的情况
            if (to && next) {
                userLoginSuccessInit(to, next);
            } else {
                let userAccessDomain = window.location.host + "?v=" + new Date().getTime();
                routerRandomDomain(routeLocationHash, userAccessDomain);
            }
        } else if (checkAccessDomainNameIsTrust) {
            let trustConfig = getTrustDomainNameConfig();
            let userTrustLevel = responseData.userTrustLevel;
            if (userTrustLevel && userTrustLevel.level && userTrustLevel.level >= trustConfig.level) {
                //因为videoHomeLogin页面手机号登录会传递空值的情况
                if (to && next) {
                    userLoginSuccessInit(to, next);
                } else {
                    let userAccessDomain = window.location.host + "?v=" + new Date().getTime();
                    routerRandomDomain(routeLocationHash, userAccessDomain);
                }
            } else {
                routerRandomDomain(routeLocationHash, responseData.userAccessDomain);
            }
        } else {
            routerRandomDomain(routeLocationHash, responseData.userAccessDomain);
        }
    }
}

/**
 * 判断微信端用户访问随机域名
 * @param routeLocationHash
 * @param userAccessDomain
 */
function routerRandomDomain(routeLocationHash, userAccessDomain) {
    if (userAccessDomain) {
        httpRequest({
            method: 'GET',
            url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "v2/user/getVideoUserDefaultLoginCode"
        }).then((response) => {
            if (response) {
                if (response.data.code === "success") {
                    if (routeLocationHash.indexOf("?") > -1) {
                        window.location.replace(window.location.protocol + "//" + userAccessDomain + "/" + routeLocationHash + "&loginCode=" + response.data.loginCode);
                    } else {
                        window.location.replace(window.location.protocol + "//" + userAccessDomain + "/" + routeLocationHash + "?loginCode=" + response.data.loginCode);
                    }
                }
            }
        });
    } else {
        routerSecurityPage();
    }
}

/**
 * 根据loginCode模拟用户登录

 * @param loginCode
 * @param to
 * @param next
 */
function userSimulateLoginByLoginCode(loginCode, to, next) {
    httpRequest({
        method: 'GET',
        url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "v2/user/videoUserDefaultLogin",
        params: {loginCode: loginCode},
    }).then((response) => {
        if (response.data.code === "success") {
            getUserInfoStore().initUserLoginState(response.data.loginUserId);
            delete to.query.loginCode;
            getUserInfoStore().updateAccessToken().then((res) => {
                if (res) {
                    userLoginSuccessInit(to, next);
                } else {
                    //重新请求token失败，跳转到登录页
                    userUnusableDialog();
                }
            });
        } else {
            window.location.replace(window.location.protocol + "//" + window.location.host + "/" + window.location.hash.replace("&loginCode=" + loginCode, "").replace("?loginCode=" + loginCode, ""));
        }
    });
}

/**
 * 初始化微信公众号用户登录信息
 * @param to
 * @param next
 */
function userLoginSuccessInit(to, next) {
    allowUserAccessTo(to, next);
    getUserInfoStore().getUserInfo();
}

/**
 * 跳转安全页
 */
function routerSecurityPage() {
    window.location.replace(getCommonInfoStore().systemConfigInfo.securityPagePath);
}

function checkAccessDomainNameIsTrust() {
    let flag = false;
    let configList = getCommonInfoStore().systemConfigInfo.trustUserAccessConfig;
    configList.forEach((item) => {
        if (window.location.host === item.domainName) {
            flag = true;
        }
    })
    return flag;
}
function getTrustDomainNameConfig() {
    let config = {
        domainName: '',
        level: 0
    };
    let configList = getCommonInfoStore().systemConfigInfo.trustUserAccessConfig;
    configList.forEach((item) => {
        if (window.location.host === item.domainName) {
            config = item;
        }
    })
    return config;
}
/**
 * 模拟登录
 * @param to
 * @param next
 */
function gaolatVideoSimulateLogin(to, next) {
    // ohLwCt-EX4Ia5XRKnGShpQWVeXoo
    // ohLwCtygMdAYviea2wG-qxzk-Zrs 鋥
    // ohLwCt73zvqaNi0b7njmmzmBECcc 文
    // ohLwCtwiIB7nKyiEZO8ShlDhr6LM 贤
    // ohLwCt6gsiODdlblLgXfnt1mK7WY 喜庆莲莲
    httpRequest({
        method: 'GET',
        url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "web/user/login",
        params: {unionid: "ohLwCt-EX4Ia5XRKnGShpQWVeXoo"}
    }).then((response) => {
        if (response.data.err_code === 200) {
            let userJson = JSON.parse(response.data.data);
            getUserInfoStore().initUserLoginState(userJson.userId, userJson.access_token);
            userLoginSuccessInit(to, next);
        } else if (response.data.err_msg !== "") {
            showFailToast(response.data.err_msg);
        }
    });
}
/**
 * 根据userId模拟用户登录
 * @param to
 * @param next
 */
function userSimulateLoginByUserId(to, next) {
    //内嵌时部分页面会的登录用户会用loginUserId= 传参，部分会用userId= 传参
    const userId = ref('');
    if (to.query.loginUserId) {
        userId.value = to.query.loginUserId;
    } else if (to.query.userId) {
        userId.value = to.query.userId;
    }
    if (userId.value && userId.value !== '' && userId.value !== 'null') {
        getUserInfoStore().clearUserInfo();
        getUserInfoStore().initUserLoginState(userId.value);
        getUserInfoStore().updateAccessToken().then((res)=> {
            if (res) {
                userLoginSuccessInit(to, next);
            } else {
                showDialog({
                    message: '无法正常登录，请退出重试',
                }).then(() => {
                    // on close
                    if (getCommonInfoStore().accessSource === 'wxAppLet') {
                        wxJsSdk.miniProgram.navigateBack({delta: 1})
                    } else {
                        getRouterStore().back();
                    }
                });
            }
        });
    } else {
        allowUserAccessTo(to, next);
    }
}