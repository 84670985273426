<script setup>
import {RouterView} from 'vue-router';
import tabbarHome from '@/components/tabbarHome.vue';
import {useWindowSize} from "@vant/use";
import {onMounted, ref, watch} from "vue";
import {useCommonInfoStore} from "@/stores/commonInfoStore.js";
import SystemMaintainPopup from "@/components/systemMaintainPopup.vue";
import myInvitationCoursePopup from "@/components/myInvitationCoursePopup.vue";
import unfoldAppPopup from "@/components/unfoldAppPopup.vue";
import NewUserRegisterPopup from "@/components/newUserRegisterPopup.vue";
import vanNotifyPopup from "@/components/vanNotifyPopup.vue";
import {isMobile} from "@/assets/js/commonUtil.js";

const commonInfoStore = useCommonInfoStore();
const stackKeepAliveList = ["/videoTabHome", "/video3DTabHome", "/instructorList", "/predictedList", "/found", "/my"];
const {width, height} = useWindowSize();
commonInfoStore.setWindowSizeValue(width.value, height.value);
watch([width, height], () => {
  commonInfoStore.setWindowSizeValue(width.value, height.value);
});
const initCommonPopup = ref(false);
setTimeout(() => {
  initCommonPopup.value = true;
}, 3500)

onMounted(() => {
  if (!isMobile()) {
    judgeExplorer();
  }
  //解决调整微信字体对网站的整体布局
  if (typeof WeixinJSBridge === "object" && typeof WeixinJSBridge.invoke === "function") {
    handleFontSize();
  } else {
    if (document.addEventListener) {
      document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
    } else if (document.attachEvent) {
      document.attachEvent("WeixinJSBridgeReady", handleFontSize);
      document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
    }
  }
})

//解决调整微信字体对网站的整体布局
const handleFontSize = () => {
  WeixinJSBridge.invoke('setFontSizeCallback', {'fontSize': 0});
  WeixinJSBridge.on('menu:setfont', function () {
    WeixinJSBridge.invoke('setFontSizeCallback', {'fontSize': 0});
  });
}
//判断PC端访问正常显示移动端样式
const judgeExplorer = () => {
  let ua = navigator.userAgent,
      isWindowsPhone = /(?:Windows Phone)/.test(ua),
      isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
      isAndroid = /(?:Android)/.test(ua),
      isFireFox = /(?:Firefox)/.test(ua),
      isChrome = /(?:Chrome|CriOS)/.test(ua),
      isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
      isPhone = /(?:iPhone)/.test(ua) && !isTablet,
      isPc = !isPhone && !isAndroid && !isSymbian;
  if (isPc) {
    let useREM = Object.create({
      resize: function () {
        let config = this.config;
        let scale = this.getScale();
        this.size = Math.max(config.min, Math.min(config.max, document.documentElement.clientWidth)) / config.num / scale;
        this.style.innerHTML = "html{font-size: " + this.size + "px !important;width: 500px;margin: 0 auto;background-color:rgba(0,0,0,0.2);}";
        return this
      },
      set: function (newConfig) {
        if (newConfig) {
          for (let a in newConfig) {
            if (this.config.hasOwnProperty(a)) {
              this.config[a] = newConfig[a]
            }
          }
        }
        this.resize();
        return this
      },
      init: function () {
        this.style = document.head.appendChild(document.createElement("style"));
        let timer;
        window.addEventListener("resize", function () {
          clearTimeout(timer);
          timer = setTimeout(this.resize.bind(this), this.config.delay)
        }.bind(useREM));
        if (navigator.userAgent.indexOf("Android") > -1) {
          window.addEventListener("DOMContentLoaded", function () {
            useREM.resize()
          })
        }
        useREM.resize()
      },
      getScale: function () {
        if (document.readyState === "loading") {
          return 1
        }
        let clientWidth = document.documentElement.clientWidth;
        let size = clientWidth / 16;
        let p = document.body.appendChild(document.createElement("p"));
        p.style.cssText = "width: 16em;font-size: " + size + "px;";
        let scale = p.offsetWidth / clientWidth;
        document.body.removeChild(p);
        return scale
      }
    }, {
      style: {value: null, writable: true, configurable: false, enumerable: true},
      size: {value: 20, writable: true, configurable: false, enumerable: true},
      config: {
        value: {max: 640, min: 320, num: 16, delay: 100},
        writable: false,
        configurable: false,
        enumerable: true
      }
    });
    useREM.init();
    useREM.set({max: 640});
  }
}
</script>

<template>
  <!--  <RouterView />-->
  <router-view v-slot="{ Component }">
    <stack-keep-alive v-slot='{ key }'
                      :singleton="stackKeepAliveList">
      <component :is="Component" :key='key'/>
    </stack-keep-alive>
  </router-view>
  <!-- 底部tabbar栏 -->
  <tabbarHome/>

  <system-maintain-popup v-if="initCommonPopup" />
  <my-invitation-course-popup v-if="initCommonPopup" />
  <unfold-app-popup v-if="initCommonPopup" />
  <new-user-register-popup v-if="initCommonPopup" />
  <van-notify-popup v-if="initCommonPopup" />
</template>

<style scoped>

</style>
