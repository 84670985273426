import './assets/css/iconfont.css'
import 'amfe-flexible'
/* 引入你需要的vant组件 */
import {
    Tabbar,
    TabbarItem,
    PullRefresh,
    List,
    Search,
    Image as VanImage,
    Swipe,
    SwipeItem,
    Dialog,
    NavBar,
    Icon,
    Field,
    Cell,
    CellGroup,
    Tab,
    Tabs,
    Divider,
    Empty,
    BackTop,
    Popup,
    Picker,
    Button,
    CountDown,
    ConfigProvider,
    IndexBar,
    IndexAnchor,
    ActionSheet,
    DatePicker,
    Area,
    ImagePreview,
    Uploader,
    Sticky,
    Sidebar,
    SidebarItem,
    Circle,
    DropdownMenu,
    DropdownItem,
    Collapse,
    CollapseItem,
    CouponList,
    Card,
    NoticeBar,
    Progress,
    Checkbox,
    CheckboxGroup,
    TextEllipsis,
    Loading,
    RadioGroup,
    Radio,
    Badge,
    Notify,
    FloatingBubble
} from 'vant';
/* 引入组件样式 */
import 'vant/lib/index.css';
/* 主题定制文件 一定要放到vant的样式后面，这样主题定制修改后的样式就会覆盖原先的样式 */
import './assets/css/main.css';

import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

/* 使用vue-router路由 */
import router from './router';
app.use(router);
import stackKeepAlive from 'stack-keep-alive';
app.use(stackKeepAlive);
/* 使用pinia状态管理器 */
import pinia from './stores/index.js';
app.use(pinia);

/* 使用vant组件 */
app.use(Tabbar);
app.use(TabbarItem);
app.use(PullRefresh);
app.use(List);
app.use(Search);
app.use(VanImage);
app.use(Swipe);
app.use(SwipeItem);
app.use(Dialog);
app.use(NavBar);
app.use(Icon);
app.use(Field);
app.use(Cell);
app.use(CellGroup);
app.use(Tab);
app.use(Tabs);
app.use(Divider);
app.use(Empty);
app.use(BackTop);
app.use(Popup);
app.use(Picker);
app.use(Button);
app.use(CountDown);
app.use(ConfigProvider);
app.use(IndexBar);
app.use(IndexAnchor);
app.use(ActionSheet);
app.use(DatePicker);
app.use(Area);
app.use(ImagePreview);
app.use(Uploader);
app.use(Sticky);
app.use(Sidebar);
app.use(SidebarItem);
app.use(Circle);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(Collapse);
app.use(CollapseItem);
app.use(CouponList);
app.use(Card);
app.use(NoticeBar);
app.use(Progress);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(TextEllipsis);
app.use(Loading);
app.use(Radio);
app.use(RadioGroup);
app.use(Badge);
app.use(Notify);
app.use(FloatingBubble);

app.mount('#app')
