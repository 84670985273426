<script setup>
import {useCommonInfoStore} from '@/stores/commonInfoStore.js';
import {ref, watch, nextTick} from 'vue'
import {useRoute} from 'vue-router';
import {useRouterStore} from '@/stores/router.js';
import {useUserInfoStore} from '@/stores/userInfo.js';
import {checkInStringArray} from '@/assets/js/commonUtil.js'
import {useGetWeChatShareInfo} from '@/assets/js/commonComposables.js'
import {routerVideo3D, routerVideoTabHome} from "@/assets/js/commonFunction.js";

const routerStore = useRouterStore();
const userInfoStore = useUserInfoStore();
const commonInfoStore = useCommonInfoStore();
const route = useRoute();

const tabbarActive = ref(0);
const tabbarShowFlag = ref(false);
const routeName = {
  1: 'instructorList',
  2: 'predictedList',
  3: 'foundPage',
  4: 'my',
};

const onTabbarChange = (index) => {
  if (index === 0) {
    if (commonInfoStore.systemLottery === 5) {
      routerVideo3D();
    } else {
      routerVideoTabHome();
    }
  } else {
    routerStore.forward({
      name: routeName[index]
    });
  }
};

const tabbarActiveObj = {
  '/videoTabHome': 0,
  '/video3DTabHome': 0,
  '/instructorList': 1,
  '/predictedList': 2,
  '/found': 3,
  '/my': 4,
};
watch(
    route,
    (newRoute, oldRoute) => {
      if (checkInStringArray(newRoute.path, ['/videoTabHome', '/video3DTabHome', '/instructorList', '/predictedList', '/found', '/my'])) {
        tabbarShowFlag.value = true;
        tabbarActive.value = tabbarActiveObj[newRoute.path];
      } else {
        tabbarShowFlag.value = false;
      }
      getVideoTabbarHeight();
      //微信浏览器并且当前访问域名为JS接口安全域名
      if (commonInfoStore.accessSource === 'wxPublic' && checkInStringArray(window.location.host, commonInfoStore.systemConfigInfo.wechatShareSafeDomainNameList)) {
        useGetWeChatShareInfo(newRoute);
      }
    }
);

const tabbarHomeRef = ref(null);
const getVideoTabbarHeight = () => {
  nextTick(() => {
    tabbarShowFlag.value && commonInfoStore.setTabbarHomeHeightValue(tabbarHomeRef.value.$el.offsetHeight);
  })
}
const routerInstructorVideoList = () => {
  routerStore.forward({
    name: 'instructorVideoList'
  });
}

const themeVars = ref({
  tabbarItemFontSize: '14px',
  tabbarHeight: '50px'
});
const themeVarsBig = ref({
  tabbarItemFontSize: '22px',
  tabbarHeight: '36px'
});
</script>

<template>
  <van-config-provider :theme-vars="parseInt(userInfoStore.userInfo.userBasicData.bigWordVersion) === 1 ? themeVarsBig: themeVars">
    <van-tabbar v-if="tabbarShowFlag"
                ref="tabbarHomeRef"
                v-model="tabbarActive"
                active-color="#f55c02"
                inactive-color="#b9b8b8"
                @change="onTabbarChange">
      <van-tabbar-item v-if="tabbarActive === 0 && userInfoStore.userInfo.userBasicData.instructorType === 1">
        <div class="releaseVideoButton" @click="routerInstructorVideoList">+</div>
      </van-tabbar-item>
      <van-tabbar-item v-else>
        <span>视频</span>
        <template #icon v-if="parseInt(userInfoStore.userInfo.userBasicData.bigWordVersion) !== 1">
          <i class="iconfont van-icon-shipin1"></i>
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>讲师</span>
        <template #icon v-if="parseInt(userInfoStore.userInfo.userBasicData.bigWordVersion) !== 1">
          <i class="iconfont van-icon-jiangshi"></i>
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>预测</span>
        <template #icon v-if="parseInt(userInfoStore.userInfo.userBasicData.bigWordVersion) !== 1">
          <i class="iconfont van-icon-bangdan"></i>
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>发现</span>
        <template #icon v-if="parseInt(userInfoStore.userInfo.userBasicData.bigWordVersion) !== 1">
          <i class="iconfont van-icon-faxian"></i>
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>我的</span>
        <template #icon v-if="parseInt(userInfoStore.userInfo.userBasicData.bigWordVersion) !== 1">
          <i class="iconfont van-icon-wode"></i>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </van-config-provider>
</template>

<style scoped>
.van-tabbar .iconfont {
  font-size: 19px;
}

.releaseVideoButton {
  position: relative;
  top: -2px;
  height: 30px;
  width: 50px;
  border-radius: 10px;
  background: #f55c02;
  color: #fff;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>