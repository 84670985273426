import Stomp from 'stompjs';
import {defineStore} from "pinia";
import {ref} from "vue";
import {useUserInfoStore} from "@/stores/userInfo.js";

const getUserInfoStore = () => {
    return useUserInfoStore();
}

export const useRabbitMqStore = defineStore(
    'rabbitMqReceivedMsg',
    () => {
        const rabbitMqReceivedMsg = ref({
            followInstructorQxcVideoUpdate: false,
            followInstructorPlwVideoUpdate: false
        });

        const rabbitMqConfig = {
            ip: "47.112.172.161",
            portStomp: "15674",
            username: "weishequ",
            password: "chukar2018",
            domainName: "q.niudie.cc",
            heartBeat: {outgoing: 30000,incoming: 30000},
            expires: 300000,
            queues: [
                'gaolat_video_queue_' + getUserInfoStore().userInfo.userId
            ]
        }

        const connectToRabbitMq = () => {
            // 初始化 ws 对象
            const connectionWebSocketUrl = 'ws://' + rabbitMqConfig.domainName + '/ws';
            const socket = new WebSocket(connectionWebSocketUrl);
            // 获得Stomp client对象
            const stompClient = Stomp.over(socket);

            // 定义心跳时间
            stompClient.heartbeat.outgoing = rabbitMqConfig.heartBeat.outgoing;
            stompClient.heartbeat.incoming = rabbitMqConfig.heartBeat.incoming;

            const rabbitMqConnectSuccess = () => {
                rabbitMqConfig.queues.forEach((queue) => {
                    stompClient.subscribe(queue, (messageOutput) => {
                        const receivedMessage = JSON.parse(messageOutput.body);
                        if (receivedMessage.messageType === 3) {
                            rabbitMqReceivedMsg.value.followInstructorQxcVideoUpdate = receivedMessage.followUpdate;
                        } else if (receivedMessage.messageType === 4) {
                            rabbitMqReceivedMsg.value.followInstructorPlwVideoUpdate = receivedMessage.followUpdate;
                        }
                    }, {
                        durable: true,
                        'auto-delete': true
                    });
                })
            }

            const rabbitMqConnectFail = () => {
                console.log('连接rabbitMq出错，尝试重新连接');
                if (stompClient.connected) {
                    stompClient.disconnect(() => {
                        stompClient.connect(rabbitMqConfig.username, rabbitMqConfig.password, rabbitMqConnectSuccess);
                    })
                } else {
                    stompClient.connect(rabbitMqConfig.username, rabbitMqConfig.password, rabbitMqConnectSuccess);
                }
            }

            stompClient.connect(rabbitMqConfig.username, rabbitMqConfig.password, rabbitMqConnectSuccess, rabbitMqConnectFail);
        };

        return {
            rabbitMqReceivedMsg,
            rabbitMqConfig,
            connectToRabbitMq
        }
    },
    {
        persist: {
            storage: sessionStorage
        }
    }
)