import CryptoJS from "crypto-js";
// npm install crypto-js --save-dev
//随机生成指定数量的32进制key
export default {
  /**
   * 加密（需要先加载lib/aes/aes.min.js文件）
   * @param word
   * @returns {*}
   */
  encrypt(word) {
    let key = CryptoJS.enc.Utf8.parse("abcdefgabcdefg12");
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return encrypted.toString();
  },
  /**
   * 解密
   * @param word
   * @returns {*}
   */
  decrypt(word) {
    let key = CryptoJS.enc.Utf8.parse("abcdefgabcdefg12");
    let decrypt = CryptoJS.AES.decrypt(word, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  }
};
