import { ref } from 'vue'
import dayjs from 'dayjs';

/*
  函数，加法函数，用来得到精确的加法结果
  说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
  参数：arg1：第一个加数；arg2第二个加数；d要保留的小数位数（可以不传此参数，如果不传则不处理小数位数）
  调用：Calc.Add(arg1,arg2,d)
  返回值：两数相加的结果
  */
export function numberAdd(arg1, arg2) {
  if (arg1 != null && arg2 != null) {
    arg1 = arg1.toString()
    arg2 = arg2.toString()
    let arg1Arr = arg1.split('.'), arg2Arr = arg2.split('.'), d1 = arg1Arr.length === 2 ? arg1Arr[1] : '',
        d2 = arg2Arr.length === 2 ? arg2Arr[1] : ''
    let maxLen = Math.max(d1.length, d2.length)
    let m = Math.pow(10, maxLen)
    let result = Number(((arg1 * m + arg2 * m) / m).toFixed(maxLen))
    let d = arguments[2]
    return typeof d === 'number' ? Number((result).toFixed(d)) : result
  }
}
/*
函数：减法函数，用来得到精确的减法结果
说明：函数返回较为精确的减法结果。
参数：arg1：第一个加数；arg2第二个加数；d要保留的小数位数（可以不传此参数，如果不传则不处理小数位数
调用：Calc.Sub(arg1,arg2)
返回值：两数相减的结果
*/
export function numberSub(arg1, arg2) {
  if (arg1 != null && arg2 != null) {
    return numberAdd(arg1, -Number(arg2), arguments[2]);
  }
}
/*
  函数：乘法函数，用来得到精确的乘法结果
  说明：函数返回较为精确的乘法结果。
  参数：arg1：第一个乘数；arg2第二个乘数；d要保留的小数位数（可以不传此参数，如果不传则不处理小数位数)
  调用：Calc.Mul(arg1,arg2)
  返回值：两数相乘的结果
  */
export function numberMul(arg1, arg2) {
  if (arg1 != null && arg2 != null) {
    let r1 = arg1.toString(), r2 = arg2.toString(), m, resultVal, d = arguments[2];
    m = (r1.split(".")[1] ? r1.split(".")[1].length : 0) + (r2.split(".")[1] ? r2.split(".")[1].length : 0);
    resultVal = Number(r1.replace(".", "")) * Number(r2.replace(".", "")) / Math.pow(10, m);
    return typeof d !== "number" ? Number(resultVal) : Number(resultVal.toFixed(parseInt(d)));
  }
}
/*
函数：除法函数，用来得到精确的除法结果
说明：函数返回较为精确的除法结果。
参数：arg1：除数；arg2被除数；d要保留的小数位数（可以不传此参数，如果不传则不处理小数位数)
调用：Calc.Div(arg1,arg2)
返回值：arg1除于arg2的结果
*/
export function numberDiv(arg1, arg2) {
  if (arg1 != null && arg2 != null) {
    let r1 = arg1.toString(), r2 = arg2.toString(), m, resultVal, d = arguments[2];
    m = (r2.split(".")[1] ? r2.split(".")[1].length : 0) - (r1.split(".")[1] ? r1.split(".")[1].length : 0);
    resultVal = Number(r1.replace(".", "")) / Number(r2.replace(".", "")) * Math.pow(10, m);
    return typeof d !== "number" ? Number(isNaN(resultVal) ? 0 : resultVal) : Number(resultVal.toFixed(parseInt(d)));
  }
}
/**
 * 调用浏览器 localStorage 保存缓存信息
 */
export function setLocalStorage(key, value) {
  let curTime = new Date().getTime() //毫秒级别
  localStorage.setItem(key, JSON.stringify({ data: value, time: curTime }))
}

/**
 * 根据key 调用浏览器 localStorage 获取缓存信息
 */
export function getLocalStorage(key, exp) {
  let data = localStorage.getItem(key)
  if (data) {
    try {
      let dataObj = JSON.parse(data)
      if (exp) {
        if (dataObj.time && new Date().getTime() - dataObj.time > exp) {
          console.log('信息已过期')
          return null
        } else {
          return dataObj.data
        }
      } else {
        return dataObj.data
      }
    } catch (err) {
      return null
    }
  } else {
    return null
  }
}

/**
 * 清除浏览器 localStorage 中的缓存信息
 */
export function clearLocalStorage() {
  localStorage.clear()
}

/**
 * 根据key 调用浏览器 localStorage 移除缓存信息
 */
export function removeLocalStorage(key) {
  localStorage.removeItem(key)
}

/**
 * 强制保留2位小数，不足补 0
 */
export function returnFloat(value) {
  value = Math.round(parseFloat(value) * 100) / 100
  let s = value.toString().split('.')
  if (s.length === 1) {
    value = value.toString() + '.00'
    return value
  }
  if (s.length > 1) {
    if (s[1].length < 2) {
      value = value.toString() + '0'
    }
    return value
  }
}

/**
 * 获取指定日期下一月
 */
export function getNextMonth(dateString) {
  let dateStr = dateString.replace(/-/g, "/");
  let dateTime = Date.parse(dateStr);
  let date = new Date(dateTime);
  date.setMonth(date.getMonth() + 1);
  let year = date.getFullYear();
  let mon = date.getMonth() + 1;
  let day = date.getDate();
  return year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
}

/**
 * 判断是否为IOS终端 还是Android终端 是IOS则返回true 否则返回false 默认为Android终端
 */
export function isIOSOrAndroid() {
  let userAgent = navigator.userAgent
  return /(iPhone|iPod|iPad|Mac)/.test(userAgent)
}

/**
 * 判断是否为苹果Safari浏览器
 */
export function isIOSOrAndroidBrowser() {
  return (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))
}

/**
 * 判断是否为手机号
 */
export function isPhoneNumberAvailable(phoneNumber) {
  let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  return myreg.test(phoneNumber)
}

/**
 * 获取手机终端信息
 */
export function getTerminalInfo() {
  let app = navigator.appVersion
  let terminalInfo = ''
  if (app) {
    terminalInfo = app.substring(app.indexOf('(') + 1, app.indexOf(')'))
  }
  return terminalInfo
}

/**
 * 判断是否为微信内置浏览器
 */
export function isWeixinBrowser() {
  let userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf("micromessenger") !== -1;
}

/**
 * 判断是否为移动端
 */
export function isMobile() {
  return ('ontouchstart' in document.documentElement) || (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
}

/**
 * 判断该数组中是否存在该数值
 */
export function checkInNumberArray(value, numberArray) {
  return (numberArray.indexOf(parseInt(value)) > -1)
}

/**
 * 判断该数组中是否存在该字符串
 */
export function checkInStringArray(value, stringArray) {
  if (stringArray) {
    return (stringArray.indexOf(value) > -1)
  }
}

/**
 * 判断该字符串逐一与数组中的元素比对，是否包含数组的元素
 */
export function stringContainArrayValue(value, stringArray) {
  let flag = false
  stringArray.forEach((item) => {
    if (value.indexOf(item) > -1) {
      flag = true
    }
  })
  return flag
}

/**
 * 判断该字符串逐一与数组中的元素比对，是否包含数组的元素,若包含返回对应的ID
 */
export function isStringInArrayField(str, array, field) {
  if (array.some(item => str === item[field])) {
    return array.find(item => str === item[field]).id;
  } else {
    return "noId"
  }
}

/**
 * 判断该数组中是否包含了该数值
 */
export function simpeNumber(value) {
  return (parseInt(value) > 9999 ? (parseInt(value) / 10000).toFixed(1) + 'W' : value)
}
/**
 * 获取链接中指定参数名的值
 */
export function getUrlParamValByHash(name) {
  let locationHash = window.location.hash
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)') //构造一个含有目标参数的正则表达式对象
  let r = locationHash.substring(locationHash.indexOf('?') + 1).match(reg) //匹配目标参数
  if (r != null) {
    return decodeURIComponent(r[2])
  }
  return null
}
/**
 * 获取链接中指定参数名的值
 */
export function getUrlParamValBySearch(name) {
  let locationSearch = window.location.search;
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  let r = locationSearch.substring(locationSearch.indexOf("?") + 1).match(reg); //匹配目标参数
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
}
/**
 * 校验密码强度
 * @param passwordValue
 */
export function checkPasswordStrong(passwordValue) {
  const modes = ref(0);
  if (passwordValue.length < 1) {
    return modes.value;
  }
  //校验密码是否含有数字
  if (/\d/.test(passwordValue)) {
    modes.value++;
  }
  //校验密码是否含有大小写字母
  if (/[a-z]/.test(passwordValue) || /[A-Z]/.test(passwordValue)) {
    modes.value++;
  }
  //校验密码是否含有特殊字符
  if (/\W/.test(passwordValue)) {
    modes.value++;
  }
  return modes.value;
}
/**
 * 自定义函数名：PrefixZero
 * @param num： 被操作数
 * @param n： 固定的总位数
 */
export function PrefixZero(num, n) {
  return (Array(n).join(0) + num).slice(-n);
}
/*
* 生成随机字符串
*/
export function generateRandomString(length) {
  const result = ref('');
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < length; i++) {
    var randomIndex = Math.floor(Math.random() * characters.length);
    result.value += characters.charAt(randomIndex);
  }
  return result.value;
}
/*
* 生成随机字符串
*/
export function generateRandomVersion() {
  return dayjs(new Date()).format('YYMMDDHHmmss');
}
/*
* 返回对象中包含了指定value值的 key对象
*/
export function findKeyByValueIncludesStr(obj, str) {
  return Object.keys(obj).filter(key => obj[key].includes(str));
}
/*
* 返回对象中包含了指定key值开头的 key对象
*/
export function findKeyByKeyStartsWithStr(obj, str) {
  return Object.keys(obj).filter(key => key.startsWith(str));
}
/*
* 返回对象中包含了指定value值开头的 key对象
*/
export function findKeyByValueStartsWithStr(obj, str) {
  return Object.keys(obj).filter(key => obj[key].startsWith(str));
}