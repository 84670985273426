<script setup>
import {ref, watch} from 'vue'
import {useUserInfoStore} from '@/stores/userInfo.js'
import {useRoute} from "vue-router";

const userInfoStore = useUserInfoStore();
const route = useRoute();
const showMyInvitationCoursePopup = ref(false);
watch(
    () => userInfoStore.userInfo.userBasicData,
    (newUserBasicData, oldUserBasicData) => {
      //俩个小时内不再弹框
      if (newUserBasicData.invitationUserId && newUserBasicData.invitationUserId !== '' && route.query.isRegisterNewUser) {
        showMyInvitationCoursePopup.value = true;
      }
    }
);

const closeMyInvitationCoursePopup = () => {
  showMyInvitationCoursePopup.value = false;
}
</script>

<template>
  <div class="myInvitationCoursePopup">
    <van-popup v-model:show="showMyInvitationCoursePopup">
      <div class="myInvitationCourseAllCt">
        <div class="myInvitationCourseHeaderImg">
          <van-image :src="userInfoStore.userInfo.userBasicData.iconUrl"/>
        </div>
        <div class="myInvitationCourseUserName">{{ userInfoStore.userInfo.userBasicData.userName }}</div>
        <div class="myInvitationCourseTitle">恭喜你！</div>
        <div class="myInvitationCourseTitle3">{{ userInfoStore.userInfo.userBasicData.invitationUserName }}</div>
        <div class="myInvitationCourseTitle">成功邀请了你！</div>
        <div class="myInvitationCourseStateList1">你和TA各得系统奖励的50元代金券！</div>
        <div class="myInvitationCourseButton">
          <span @click="closeMyInvitationCoursePopup">知道了</span>
        </div>
        <div class="popUp_cancel_content">
          <div class="popUp_cancel_button" @click="closeMyInvitationCoursePopup">X</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<style>
.myInvitationCoursePopup .van-popup {
  width: 80%;
  overflow-y: initial;
  background: #ffffff;
  border-radius: 5px;
}

.myInvitationCoursePopup .myInvitationCourseAllCt {
  width: 100%;
  position: relative;
}

.myInvitationCoursePopup .myInvitationCourseHeaderImg {
  width: 100%;
  height: 120px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myInvitationCoursePopup .myInvitationCourseHeaderImg .van-image img {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  border: 1px solid #f55c02;
}

.myInvitationCoursePopup .myInvitationCourseUserName {
  width: 100%;
  height: 30px;
  font-size: 18px;
  text-align: center;
  color: #0a8cea;
}

.myInvitationCoursePopup .myInvitationCourseTitle {
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ec292d;
}

.myInvitationCoursePopup .myInvitationCourseTitle3 {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0a8cea;
}

.myInvitationCoursePopup .myInvitationCourseStateList {
  width: 88%;
  margin: 0 auto;
  font-size: 16px;
  padding-bottom: 5px;
  text-align: left;
  display: flex;
  justify-content: left;
  align-items: center;
}

.myInvitationCoursePopup .myInvitationCourseStateList span {
  float: left;
}

.myInvitationCoursePopup .courseStateListButton {
  border: 1px solid #f55c02;
  color: #f55c02;
  font-size: 15px;
  float: left;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
}

.myInvitationCoursePopup .myInvitationCourseStateList1 {
  width: 88%;
  margin: 0 auto;
  font-size: 16px;
  line-height: 25px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myInvitationCoursePopup .myInvitationCourseButton {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myInvitationCoursePopup .myInvitationCourseButton span {
  width: 50%;
  height: 40px;
  background: #f55c02;
  font-size: 16px;
  border-radius: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myInvitationCoursePopup .popUp_cancel_content {
  position: absolute;
  left: 0;
  bottom: -80px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myInvitationCoursePopup .popUp_cancel_button {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  font-size: 20px;
  border: 1px solid #fff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>