import {ref} from 'vue'
import {defineStore} from 'pinia'

export const useCommonInfoStore = defineStore(
    'commonInfo',
    () => {
        const accessSource = ref('');
        const setAccessSourceValue = (value) => {
            if (accessSource.value === '') {
                accessSource.value = value;
            }
        };

        const accessSourceClient = ref('');
        const setAccessSourceClientValue = (value) => {
            if (accessSourceClient.value === '') {
                accessSourceClient.value = value;
            }
        };

        const shareUserId = ref('');
        const setShareUserIdValue = (value) => {
            if (shareUserId.value === '') {
                shareUserId.value = value;
            }
        };

        const systemConfigInfo = ref({});
        const setSystemConfigInfoValue = (value) => {
            systemConfigInfo.value = value;
        };

        const tabbarHomeHeight = ref(0);
        const setTabbarHomeHeightValue = (value) => {
            tabbarHomeHeight.value = value;
        };

        const activityConfigInfo = ref(null);
        const setActivityConfigInfoValue = (value) => {
            activityConfigInfo.value = value;
        };

        const activityDetailsConfigInfo = ref([]);
        const setActivityDetailsConfigInfoValue = (value) => {
            activityDetailsConfigInfo.value = value;
        };

        const windowWidth = ref(0);
        const windowHeight = ref(0);
        const setWindowSizeValue = (width, height) => {
            windowWidth.value = width;
            windowHeight.value = height;
        };

        const advertisingListInfo = ref([]);
        const setAdvertisingListInfoValue = (value) => {
            advertisingListInfo.value = value;
        };

        const systemLottery = ref(1);
        const setSystemLotteryValue = (value) => {
            systemLottery.value = value;
        };

        return {
            accessSource,
            setAccessSourceValue,
            accessSourceClient,
            setAccessSourceClientValue,
            shareUserId,
            setShareUserIdValue,
            systemConfigInfo,
            setSystemConfigInfoValue,
            tabbarHomeHeight,
            setTabbarHomeHeightValue,
            activityConfigInfo,
            setActivityConfigInfoValue,
            activityDetailsConfigInfo,
            setActivityDetailsConfigInfoValue,
            windowWidth,
            windowHeight,
            setWindowSizeValue,
            advertisingListInfo,
            setAdvertisingListInfoValue,
            systemLottery,
            setSystemLotteryValue
        }
    },
    {
        persist: {
            storage: sessionStorage
        }
    }
)