<script setup>
import {useOpenChukarVideoApp} from '@/assets/js/commonComposables.js'
import globalConstant from '@/assets/js/globalConstant.js'
import {useCommonInfoStore} from '@/stores/commonInfoStore.js'
import {checkInStringArray, isIOSOrAndroid, isMobile} from '@/assets/js/commonUtil.js'
import {useUserInfoStore} from "@/stores/userInfo.js";
import {useRoute} from "vue-router";
import {ref, watch} from "vue";

const commonInfoStore = useCommonInfoStore();
const userInfoStore = useUserInfoStore();
const route = useRoute();
const isShowUnfoldApp = ref(false);
const routePathArray = ["/", "/videoTabHome", "/buyVideoHome", "/videoVipHome", "/buyVideoVip", "/rewardUser", "/goldCoinsTopUp",
  "/instructorReleaseVideo", "/instructorUpLoadWinning", "/instructorChangeVideo"];
watch(
    () => route.path,
    (newPath, oldPath) => {
      isShowUnfoldApp.value = !checkInStringArray(newPath, routePathArray);
    }
);
</script>

<template>
  <div class="unfoldAppPopup">
    <div class="videoTabDownApp" @click.stop="useOpenChukarVideoApp().downLoadApp()"
         v-if="isShowUnfoldApp && isMobile() && !isIOSOrAndroid() && commonInfoStore.accessSource === 'webH5' && userInfoStore.checkUserLoginState()">
      <div class="videoTabDownAppButton">
        <div class="videoTabDownImg">
          <van-image width="25" :src="globalConstant.CHUKAR_STATIC_FILE_ACCESS_URL + 'static/images/zgjt_logo.png'"/>
        </div>
        <div class="videoTabDownState">
          <span>打开鹧鸪APP</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.videoTabDownAppButton {
  height: 40px;
  background: #f55c02;
  border-radius: 2px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 0 0 4px #999999;
}

.videoTabDownAppButton:link {
  background: #f55c02;
}

.videoTabDownAppButton:visited {
  background: #eeeeee;
}

.videoTabDownAppButton:hover {
  background: #f55c02;
}

.videoTabDownAppButton:active {
  background: #eeeeee;
}

.videoTabDownImg {
  height: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoTabDownImg img {
  width: 20px;
  height: 20px;
}

.videoTabDownState {
  height: 100%;
  font-size: 15px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-left: 10px;
}

.videoTabDownApp {
  position: absolute;
  right: 0;
  bottom: 60px;
  z-index: 50;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>