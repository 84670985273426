import {showConfirmDialog, showLoadingToast, showSuccessToast, showToast} from 'vant';
import router from '@/router/index';
import {useUserInfoStore} from '@/stores/userInfo.js';
import {useRouterStore} from '@/stores/router.js';
import {isMobile, isIOSOrAndroid, isWeixinBrowser, checkInStringArray} from '@/assets/js/commonUtil.js';
import httpRequest from "@/httpRequest/httpRequest.js";
import globalConstant from "@/assets/js/globalConstant.js";
import {useCommonInfoStore} from '@/stores/commonInfoStore.js';
import {nextTick, ref} from "vue";
import html2canvas from 'html2canvas';
import {contactCustomerServiceDialog, routerContactCustomerService} from "@/assets/js/commonFunction.js";
import wxJsSdk from 'weixin-js-sdk';
import {BosClient} from "@baiducloud/sdk";

function getUserInfoStore() {
    return useUserInfoStore();
}
function getCommonInfoStore() {
    return useCommonInfoStore();
}
function getRouterStore() {
    return useRouterStore();
}
/**
 * 手机验证码
 */
export function usePhoneVerifiCode() {
    let phoneVerifiCodeText = ref('获取验证码');
    let sendVerifiCode = ref(false);
    /**
     * 获取短信验证码
     */
    let sendPhoneVerifiCode = (phoneNumber, oprType, name) => {
        httpRequest({
            method: 'POST',
            url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "web/user/getPhoneVerifiCode",
            params: {phoneNumber: phoneNumber, oprType: oprType, name: name}
        }).then((response) => {
            if (response) {
                phoneVerifiCodeCountdown();
            }
        });
    }

    //获取验证码倒计时
    let phoneVerifiCodeCountdown = () => {
        let time = 120;
        phoneVerifiCodeText.value = time + "秒后重发";
        //1.在120秒之内，button上倒计时120秒，并显示
        let set = setInterval(() => {
            phoneVerifiCodeText.value = (--time) + "秒后重发";
        }, 1000);
        //120秒之后需要做的事情
        setTimeout(() => {
            //恢复获取验证码按钮可点击
            sendVerifiCode.value = false;
            phoneVerifiCodeText.value = "获取验证码";
            //清除计时器
            clearInterval(set);
        }, time * 1000);
    }

    return {phoneVerifiCodeText, sendVerifiCode, sendPhoneVerifiCode}
}

/**
 * 切换大字版
 */
export function useSwitchBigWordVersion() {
    const switchBigWordOrStandard = () => {
        if (parseInt(getUserInfoStore().userInfo.userBasicData.bigWordVersion) === 0) {
            openSwitchBigWordConfirmDialog();
        } else {
            openSwitchStandardConfirmDialog();
        }
    }
    const openSwitchBigWordConfirmDialog = () => {
        if (getUserInfoStore().checkUserLoginState()) {
            showConfirmDialog({
                title: '大字版模式',
                message: '<div style="text-align: center;"><span style="color: #f55c02;">将标准版切换到大字版</span><br /><span>文字更大，色彩更强，按钮更大</span></div>',
                allowHtml: true
            }).then(() => {
                // on confirm
                updateUserSideBigWordVersion(1);
            }).catch(() => {
                // on cancel
            });
        } else {
            showToast('请登录后再进行切换！');
        }
    }

    const openSwitchStandardConfirmDialog = () => {
        if (getUserInfoStore().checkUserLoginState()) {
            showConfirmDialog({
                title: '标准版模式',
                message: '<div style="text-align: center;"><span style="color: #f55c02;">将大字版切换到标准版</span><br /><span>文字大小、色彩亮度、按钮大小变为标准样式</span></div>',
                allowHtml: true
            }).then(() => {
                // on confirm
                updateUserSideBigWordVersion(0);
            }).catch(() => {
                // on cancel
            });
        } else {
            showToast('请登录后再进行切换！');
        }
    }

    /**
     * 切换大字版
     * @param bigWordVersion
     */
    const updateUserSideBigWordVersion = (bigWordVersion) => {
        httpRequest({
            method: 'POST',
            url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "v2/user/updateUserSideBigWordVersion",
            params: {bigWordVersion: bigWordVersion},
        }).then((response) => {
            if (response) {
                if (response.data.code === 'success') {
                    getUserInfoStore().setUserBigWordVersion(bigWordVersion);
                }
            }
        });
    }

    return {switchBigWordOrStandard};
}

/*
打开APP
*/
export function useOpenChukarVideoApp() {
    let downLoadApp = () => {
        if (isMobile()) {
            if (!isIOSOrAndroid() && !isWeixinBrowser()) {
                openAssignApp();
            } else {
                routerDownLoadPage();
            }
        } else {
            routerDownLoadPage();
        }
    }

    let openAssignApp = () => {
        let loadToast = showLoadingToast({
            message: '正在打开APP...',
            forbidClick: true,
            duration: 3000
        });
        setTimeout(() => {
            loadToast.close();
            window.location.href = 'zhegu://';
        }, 1000);
        //跳转到下载APP页面定时器逻辑
        let timer = setTimeout(() => {
            loadToast.close();
            // 三秒后没跳APP就跳转下载页
            showToast('还没安装最新版本APP，即将跳转到下载APP页面！');
            setTimeout(() => {
                routerDownLoadPage();
            }, 2000);
        }, 3000);

        // 监听页面visibility
        window.addEventListener('visibilitychange', () => {
            if (document.hidden) {
                //如果页面隐藏了，则表示唤起APP成功，这时候需要清除定时器（跳转到下载APP页面）
                clearTimeout(timer);
                loadToast.close();
            }
        })
    }

    let routerDownLoadPage = () => {
        if (getCommonInfoStore.accessSource === 'wxAppLet') {
            router.push({name: 'miniProgramDownLoadPage'});
        } else if (getCommonInfoStore.accessSource === "videoApp") {
            webPageCall.openBrowser("http://zgjtapp.zhegu88.cn/mobileZgjtDownload.html?v=7");
        } else {
            window.open("http://zgjtapp.zhegu88.cn/mobileZgjtDownload.html?v=7");
        }
    }

    return {downLoadApp}
}

/*
获取视频列表
*/
export function useGetVideoList() {
    /**
     * 获取首页视频列表数据
     */
    let requestVideoList = async (requestUrl, originalVideoList) => {
        let videoResult = {};
        let response = await httpRequest({method: 'GET', url: requestUrl});
        if (response) {
            let responseVideoList = [];
            if (response.data.top_items) {
                responseVideoList = response.data.top_items;
            }
            if (response.data.items) {
                responseVideoList = responseVideoList.concat(response.data.items);
            }
            let kjxxJson = response.data.kjxxJson;
            let navigator = response.data.navigator;
            let videoJsonList = buildVideoList(responseVideoList, kjxxJson, originalVideoList);
            videoResult = {
                navigator: navigator,
                videoJsonList: videoJsonList
            };
        }
        return videoResult;
    }

    //拼接视频数据
    let buildVideoList = (responseVideoList, kjxxJson, originalVideoList) => {
        let videoJsonList = [];
        if (responseVideoList.length > 0) {
            //返回视频数据列表与现有视频数据列表对比，剔除重复项
            if (originalVideoList.length > 0) {
                let tempVideoList = originalVideoList[originalVideoList.length - 1].videoList;
                if (tempVideoList.length > 10) {
                    tempVideoList = tempVideoList.slice(tempVideoList.length - 10);
                }
                tempVideoList.forEach(function (videoData, index) {
                    responseVideoList.forEach(function (videoData1, index1) {
                        if (videoData1.id === videoData.id) {
                            //移除该条视频数据
                            responseVideoList.splice(index1, 1);
                        }
                    })
                });
            }

            let videoSerial = '';
            let videoList = [];
            //组装后端返回视频数据列表
            responseVideoList.forEach(function (videoData, index) {
                if (index === 0) {
                    videoSerial = videoData.serial;
                    videoList.push(videoData);
                    if (index === responseVideoList.length - 1) {
                        let videoJson = {};
                        videoJson.serial = videoSerial;
                        videoJson.title = kjxxJson[videoJson.serial];
                        videoJson.videoList = videoList;
                        videoJsonList.push(videoJson);
                    }
                } else {
                    if (videoSerial === videoData.serial) {
                        videoList.push(videoData);
                        if (index === responseVideoList.length - 1) {
                            let videoJson = {};
                            videoJson.serial = videoSerial;
                            videoJson.title = kjxxJson[videoJson.serial];
                            videoJson.videoList = videoList;
                            videoJsonList.push(videoJson);
                        }
                    } else {
                        let videoJson = {};
                        videoJson.serial = videoSerial;
                        videoJson.title = kjxxJson[videoJson.serial];
                        videoJson.videoList = videoList;
                        videoJsonList.push(videoJson);
                        videoSerial = videoData.serial;
                        videoList = [];
                        videoList.push(videoData);
                    }
                }
            })

            //判断现有视频数据列表最后一组数据的期数与后端返回视频数据列表第一组数据的期数是否一致，如果是，则需要把俩组数据合并成为一组
            if (originalVideoList.length > 0) {
                let videoJson = originalVideoList[originalVideoList.length - 1];
                let videoJson1 = videoJsonList[0];
                if (videoJson && videoJson1 && videoJson.serial === videoJson1.serial) {
                    let videoJson2 = {};
                    videoJson2.serial = videoJson.serial;
                    videoJson2.title = videoJson.title;
                    videoJson2.videoList = videoJson.videoList.concat(videoJson1.videoList);
                    let list = originalVideoList.slice(0, originalVideoList.length - 1);
                    list.push(videoJson2);
                    videoJsonList = list.concat(videoJsonList.slice(1, videoJsonList.length));
                } else {
                    videoJsonList = originalVideoList.concat(videoJsonList);
                }
            }
        }

        return videoJsonList;
    }

    return {requestVideoList}
}

/*
获取讲师列表
*/
export function useGetInstructorList() {
    /**
     * 获取讲师列表数据
     */
    const requestInstructorList = async (requestUrl, titleActive, sortValue, originalInstructorList) => {
        let instructorResult = {};
        let response = await httpRequest({method: 'GET', url: requestUrl});
        if (response) {
            let responseInstructorList = [];
            if (response.data.top_items) {
                responseInstructorList = response.data.top_items;
            }
            if (response.data.items) {
                responseInstructorList = responseInstructorList.concat(response.data.items);
            }

            const instructorList = buildInstructorList(responseInstructorList, titleActive, sortValue, originalInstructorList);

            instructorResult = {
                navigator: response.data.navigator,
                instructorList: instructorList
            };
        }
        return instructorResult;
    }

    const buildInstructorList = (responseInstructorList, titleActive, sortValue, originalInstructorList) => {
        let instructorList = [];
        if (titleActive === 'follow' && sortValue === 2 || titleActive === 'all' && sortValue === 1) {
            instructorList = responseInstructorList.concat(originalInstructorList);
        } else {
            responseInstructorList.forEach((item) => {
                instructorList = instructorList.concat(item.instructorInfoList);
            })
            instructorList = instructorList.concat(originalInstructorList);
        }
        return instructorList;
    };

    return {requestInstructorList}
}

/**
 * 获取开奖信息
 */
export function useGetFoundkjxx() {
    /**
     * 获取开奖信息
     */
    const kjxxData = ref([]);

    const foundkjxxData = () => {
        httpRequest({
            method: 'GET',
            url: globalConstant.GAOLAT_SERVER_ACCESS_DOMAIN_NAME + "v2/prediction/kj/getKjxgxxJson",
            params: {source: "webH5"}
        }).then((response) => {
            if (response) {
                kjxxData.value = response.data;
            }
        });
    }
    foundkjxxData();
    return {kjxxData}
}

/**
 * 开奖倒计时
 */
export function openNumberCountDown(xqKjsj, lottery) {
    const qxcCountDownResult = ref({});
    const plwCountDownResult = ref({});
    const countDownData = () => {
        if (!xqKjsj) {
            return;
        }
        const endDate = new Date(xqKjsj.replace(/-/g, "/"));
        const leftTime = endDate.getTime() - new Date().getTime();
        const leftsecond = parseInt(leftTime / 1000);
        const qxcDjsDay = ref(0);
        const qxcDjsHour = ref(0);
        const qxcDjsMinute = ref(0);
        const qxcDjsSecond = ref(0);
        const plwDjsDay = ref(0);
        const plwDjsHour = ref(0);
        const plwDjsMinute = ref(0);
        const plwDjsSecond = ref(0);
        if (lottery === 1) {
            qxcDjsDay.value = Math.floor(leftsecond / (60 * 60 * 24));
            qxcDjsHour.value = Math.floor((leftsecond - qxcDjsDay.value * 24 * 60 * 60) / 3600);
            qxcDjsMinute.value = Math.floor((leftsecond - qxcDjsDay.value * 24 * 60 * 60 - qxcDjsHour.value * 3600) / 60);
            qxcDjsSecond.value = Math.floor(leftsecond - qxcDjsDay.value * 24 * 60 * 60 - qxcDjsHour.value * 3600 - qxcDjsMinute.value * 60);
            qxcCountDownResult.value = {
                qxcDjsDay: qxcDjsDay.value,
                qxcDjsHour: qxcDjsHour.value,
                qxcDjsMinute: qxcDjsMinute.value,
                qxcDjsSecond: qxcDjsSecond.value,
            };
        } else if (lottery === 2) {
            plwDjsDay.value = Math.floor(leftsecond / (60 * 60 * 24));
            plwDjsHour.value = Math.floor((leftsecond - plwDjsDay.value * 24 * 60 * 60) / 3600);
            plwDjsMinute.value = Math.floor((leftsecond - plwDjsDay.value * 24 * 60 * 60 - plwDjsHour.value * 3600) / 60);
            plwDjsSecond.value = Math.floor(leftsecond - plwDjsDay.value * 24 * 60 * 60 - plwDjsHour.value * 3600 - plwDjsMinute.value * 60);
            plwCountDownResult.value = {
                plwDjsDay: plwDjsDay.value,
                plwDjsHour: plwDjsHour.value,
                plwDjsMinute: plwDjsMinute.value,
                plwDjsSecond: plwDjsSecond.value
            };
        }
    }
    countDownData()
    return {qxcCountDownResult, plwCountDownResult}
}

/**
 * 上传图片
 */
export function useUpImg() {
    const upImg = async (imgFiles, foderName) => {
        const imgUrlsObj = ref([]);
        const dataParam = ref({});
        if (foderName) {
            dataParam.value.foderName = foderName;
        }
        const response = await httpRequest({
            method: 'POST',
            url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + 'upyun/genarateUpyunEntity',
            params: dataParam.value
        })
        if (response) {
            const data = response.data;
            for (let index = 0; index < imgFiles.length; index++) {
                //构造空对象，下面用append 方法赋值。
                const formData = new FormData();
                formData.append('policy', data.policy);
                formData.append('signature', data.signature);
                formData.append('file', imgFiles[index]);
                const result = await httpRequest({
                    method: 'POST',
                    url: data.upUrl,
                    data: formData
                })
                if (result) {
                    const obj = result.data;
                    //图片完整路径
                    const picurl = data.viewUrl + obj.url;
                    //把imaUrl转成json格式
                    let imgUrl = {};
                    //原图不带水印
                    imgUrl.picurl = picurl;
                    //原图
                    imgUrl.origin = picurl + '!v1024';
                    //图片压缩版本路径 在图片路径后面添加 ！+版本号（版本号有v80、v160、v240、v360、v640、v750 v后面的数字是图片宽度）
                    imgUrl['750'] = picurl + '!v750';
                    imgUrl['360'] = picurl + '!v360';
                    imgUrl['format'] = obj['image-type'];
                    imgUrlsObj.value.push(imgUrl);
                }
            }
        }
        return imgUrlsObj;
    }

    return {upImg}
}
/**
 * 获取用户账户信息
 */
export function useGetUserAccountInfo(pageFlag) {
    const userAccountInfo = ref({
        account_amount: 0,
        gold_coin_amount: 0,
        gold_bean_amount: 0,
        coupon_id_list: []
    });

    const getUserAccountInfo = () => {
        httpRequest({
            method: 'GET',
            url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "v2/vip/getUserAccountInfo",
            params: {pageFlag: pageFlag, nowTime: new Date().getTime()}
        }).then((response) => {
            if (response) {
                userAccountInfo.value = response.data;
            }
        });
    }
    getUserAccountInfo();

    return {userAccountInfo};
}
/**
 * 截取dom元素生成图片
 */
export async function useHtml2Canvas(htmlContainer, canvasHeight) {
    const canvas = await html2canvas(htmlContainer, {
        useCORS: true,
        height: canvasHeight,
        allowTaint: false,
        logging: false,
        letterRendering: true,
        scale: 4, // 处理模糊问题
        dpi: 300 // 处理模糊问题
    })
    // 将canvas转换为图片数据
    return canvas.toDataURL('image/png');
}
/**
 * 用户举报
 */
export function useUserReport() {
    const commonActionSheetRef = ref(null);
    const showReportActionSheet = (targetId, reportType) => {
        commonActionSheetRef.value.sheetDescription = '举报类型'
        commonActionSheetRef.value.sheetActions = [
            {
                name: "盗版",
                color: "#f55c02",
                targetId: targetId,
                reportType: reportType
            },
            {
                name: "发广告",
                color: "#f55c02",
                targetId: targetId,
                reportType: reportType
            },
            {
                name: "留有联系方式",
                color: "#f55c02",
                targetId: targetId,
                reportType: reportType
            },
            {
                name: "其他",
                color: "#f55c02",
                targetId: targetId,
                reportType: reportType
            }
        ]
        commonActionSheetRef.value.isShowSheet = true
    }
    const onActionSheet = (action, index) => {
        if (index === 1) {
            if (action.reportType === 1) {
                reportVideo(action.targetId, 2);
            } else {
                reportUser(action.targetId, 2);
            }
        } else if (index === 2) {
            if (action.reportType === 1) {
                reportVideo(action.targetId, 3);
            } else {
                reportUser(action.targetId, 3);
            }
        } else {
            getRouterStore().forward({
                name: 'videoToReportWork',
                query: {
                    reportIndex: index,
                    targetId: action.targetId,
                    reportType: action.reportType
                }
            });
        }
    }

    /**
     * 举报帖子
     * @param videoId
     * @param violationTypeId
     * @param content
     * @param link
     */
    const reportVideo = (videoId, violationTypeId, content, link) => {
        httpRequest({
            method: 'POST',
            url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "v2/spammer/feed",
            params: {target_id: videoId, violationTypeId: violationTypeId, content: content, link: link},
        }).then((response) => {
            showSuccessToast("举报成功");
        });
    }

    /**
     * 举报用户
     * @param userId
     * @param violationTypeId
     * @param content
     * @param link
     */
    const reportUser = (userId, violationTypeId, content, link) => {
        httpRequest({
            method: 'POST',
            url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "v2/spammer/user",
            params: {target_id: userId, violationTypeId: violationTypeId, content: content, link: link},
        }).then((response) => {
            showSuccessToast("举报成功");
        });
    }

    return {commonActionSheetRef, showReportActionSheet, onActionSheet, reportVideo, reportUser};
}
/**
 * 用户关注或取消关注
 */
export function useUserFollowOrCanclFollow() {
    const userFollow = async (userId) => {
        const relation = ref(null);
        const response = await httpRequest({
            method: 'POST',
            url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "v2/user/follow",
            params: {fuid: userId},
        });
        if (response) {
            relation.value = response.data.relation;
            showSuccessToast("关注成功");
        }

        return relation;
    }

    const userCanclFollow = async (userId) => {
        const relation = ref(null);
        const response = await httpRequest({
            method: 'DELETE',
            url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "v2/user/follow",
            params: {fuid: userId},
        });
        if (response) {
            relation.value = response.data.relation;
            showSuccessToast("取消成功");
        }

        return relation;
    }

    return {userFollow, userCanclFollow};
}
/**
 * 获取优惠活动配置信息
 */
export function useFavourableActivityConfigInfo() {
    const getActivityConfigInfo = async (userId) => {
        const activityConfig = ref({});

        if (!getCommonInfoStore().activityConfigInfo) {
            const response = await httpRequest({
                method: 'GET',
                url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "v2/vip/getFavourableActivityConfigInfo",
            });
            if (response) {
                getCommonInfoStore().setActivityConfigInfoValue(response.data.activityConfig);
                getCommonInfoStore().setActivityDetailsConfigInfoValue(response.data.activityDetailsConfig);
            }
        }

        activityConfig.value = getCommonInfoStore().activityConfigInfo;

        return activityConfig;
    }

    const getActivityDetailsConfig = async (userId) => {
        const activityDetailsConfig = ref([]);

        if (getCommonInfoStore().activityDetailsConfigInfo.length === 0) {
            const response = await httpRequest({
                method: 'GET',
                url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "v2/vip/getFavourableActivityConfigInfo",
            });
            if (response) {
                getCommonInfoStore().setActivityConfigInfoValue(response.data.activityConfig);
                getCommonInfoStore().setActivityDetailsConfigInfoValue(response.data.activityDetailsConfig);
            }
        }

        activityDetailsConfig.value = getCommonInfoStore().activityDetailsConfigInfo;

        return activityDetailsConfig;
    }

    return {getActivityConfigInfo, getActivityDetailsConfig};
}

export function usePayCallBackCommonMethod() {
    const payCallBackCommonMethod = (payFrom, result, callbackFuncation, aliPayFromRef, pcPayQrCodeDialogRef) => {
        if (result.resultCode === "WX_JSAPI_SUCCESS") {
            weChatJsapiPayCommon(result, callbackFuncation);
        } else if (result.resultCode === "WX_H5_SUCCESS") {
            openWxWebPayRedirectUrl(result, callbackFuncation);
        } else if (result.resultCode === "WX_APP_SUCCESS") {
            setTimeout(() => {
                payCheckDialog(result.outTradeNo, callbackFuncation);
            }, 3000);
            webPageCall.toCallWxpay(JSON.stringify(result));
        } else if (result.resultCode === "GAOLAT_PAY_SUCCESS") {
            if (typeof callbackFuncation === "function") {
                callbackFuncation();
            }
        } else if (result.resultCode === "ALI_H5_SUCCESS") {
            aliPayFromRef.aliPayFromHtml = result.aliPayFrom;
            nextTick(() => {
                document.forms[0].submit();
            })
        } else if (result.resultCode === "WX_PC_SUCCESS") {
            pcPayQrCodeDialogRef.pcPayUrl = result.codeUrl;
            pcPayQrCodeDialogRef.outTradeNo = result.outTradeNo;
            pcPayQrCodeDialogRef.topUpTotalFee = result.total_fee;
            pcPayQrCodeDialogRef.openCashBackPopupFlag = result.openCashBackPopupFlag;
        } else {
            contactCustomerServiceDialog('调用支付出错', 'replace');
        }
    }

    /**
     * 微信内调用支付
     * @param result
     * @param callbackFuncation
     */
    const weChatJsapiPayCommon = (result, callbackFuncation) => {
        if (getCommonInfoStore().accessSource === 'wxPublic') {
            if (typeof WeixinJSBridge === "undefined") {
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', () => {
                        onBridgeReady(result);
                    }, false);
                } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', () => {
                        onBridgeReady(result);
                    });
                    document.attachEvent('onWeixinJSBridgeReady', () => {
                        onBridgeReady(result);
                    });
                } else {
                    contactCustomerServiceDialog('无法调用微信支付控件', 'replace');
                }
            } else {
                if (checkInStringArray(window.location.host, [getCommonInfoStore().systemConfigInfo.wechatAuthLoginDomainName1, getCommonInfoStore().systemConfigInfo.wechatAuthLoginDomainName2, getCommonInfoStore().systemConfigInfo.testAccessDomainName])) {
                    onBridgeReady(result, callbackFuncation);
                } else {
                    routerPublicPayOrderPage(result, getCommonInfoStore().systemConfigInfo.wechatAuthLoginDomainName2, callbackFuncation);
                }
            }
        } else if (getCommonInfoStore().accessSource === 'wxAppLet') {
            routerMiniProgramPayOrderPage(result, callbackFuncation);
        }
    }

    /**
     * 微信内安全域名调用支付
     * @param payReturnParam
     * @param callbackFuncation
     */
    const onBridgeReady = (payReturnParam, callbackFuncation) => {
        const payParams = {
            "appId": payReturnParam.appId,     //公众号名称，由商户传入
            "timeStamp": payReturnParam.timeStamp,         //时间戳，自1970年以来的秒数
            "nonceStr": payReturnParam.nonce_str, //随机串
            "package": payReturnParam.package,
            "signType": payReturnParam.signType,         //微信签名方式：
            "paySign": payReturnParam.sign //微信签名
        };
        WeixinJSBridge.invoke(
            'getBrandWCPayRequest',
            payParams,
            (res) => {
                if (res.err_msg === "get_brand_wcpay_request:ok") {
                    // 使用以上方式判断前端返回,微信团队郑重提示：
                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                    checkPayOrderCommon(payReturnParam.outTradeNo, callbackFuncation);
                }
            }
        );
    }

    /**
     * 未授权域名访问跳转授权域名进行微信支付
     * @param payReturnParam
     * @param payDomainName
     * @param callbackFuncation
     */
    const routerPublicPayOrderPage = (payReturnParam, payDomainName, callbackFuncation) => {
        const payParam = {
            "appId": payReturnParam.appId, //公众号名称，由商户传入
            "timeStamp": payReturnParam.timeStamp, //时间戳，自1970年以来的秒数
            "nonceStr": payReturnParam.nonce_str, //随机串
            "package": payReturnParam.package,
            "signType": payReturnParam.signType, //微信签名方式：
            "paySign": payReturnParam.sign, //微信签名
            "historyPageLength": history.length
        };
        setTimeout(() => {
            payCheckDialog(payReturnParam.outTradeNo, callbackFuncation);
        }, 300);
        window.location.href = 'http://' + payDomainName + '?v=' + new Date().getTime() + '/#/payOrderPage?params=' + encodeURIComponent(JSON.stringify(payParam));
    }
    /**
     * 跳转小程序端微信支付
     * @param payReturnParam
     * @param callbackFuncation
     */
    const routerMiniProgramPayOrderPage = (payReturnParam, callbackFuncation) => {
        let payParam = {
            "appId": payReturnParam.appId, //公众号名称，由商户传入
            "timeStamp": payReturnParam.timeStamp, //时间戳，自1970年以来的秒数
            "nonceStr": payReturnParam.nonce_str, //随机串
            "package": payReturnParam.package,
            "signType": payReturnParam.signType, //微信签名方式：
            "paySign": payReturnParam.sign //微信签名
        };
        setTimeout(() => {
            payCheckDialog(payReturnParam.outTradeNo, callbackFuncation);
        }, 300);
        wxJsSdk.miniProgram.navigateTo({
            url: '/pages/aboutUs/aboutUs?params=' + encodeURIComponent(JSON.stringify(payParam))
        });
    }
    /**
     * 打开webH5端微信支付
     * @param payReturnParam
     * @param callbackFuncation
     * @param redirectUrl
     */
    const openWxWebPayRedirectUrl = (payReturnParam, callbackFuncation) => {
        // iOS中浏览器直接访问站点时，navigator.standalone为false,从主屏启动webapp 时，navigator.standalone为true
        if (navigator.standalone) {
            setTimeout(() => {
                payCheckDialog(payReturnParam.outTradeNo, callbackFuncation);
            }, 300);
            payReturnParam.weChatWebPayRedirectUrl = payReturnParam.weChatWebPayRedirectUrl.replace("http://", "").replace("https://", "");
        }
        window.location.href = payReturnParam.mweb_url + '&redirect_url=' + encodeURIComponent(payReturnParam.weChatWebPayRedirectUrl);
    }
    /**
     * 支付校验弹框
     * @param outTradeNo
     * @param callbackFuncation
     */
    const payCheckDialog = (outTradeNo, callbackFuncation) => {
        showConfirmDialog({
            title: '温馨提示！',
            message: '确认是否已完成支付?',
            confirmButtonText: '已支付',
            cancelButtonText: '未支付'
        }).then(() => {
            // on confirm
            checkPayOrderCommon(outTradeNo, callbackFuncation);
        }).catch(() => {
            // on cancel
        })
    }
    /**
     * 检查用户订单是否支付成功公共方法
     * @param outTradeNo
     * @param callbackFuncation
     */
    const checkPayOrderCommon =(outTradeNo, callbackFuncation) => {
        const loadingToast = showLoadingToast({
            message: '订单校验中...',
            forbidClick: true,
            duration: 0
        });
        setTimeout(() => {
            checkPayOrderIsSuccess(outTradeNo, callbackFuncation, loadingToast);
        }, 600)
    }
    /**
     * 检查用户订单是否支付成功
     * @param outTradeNo
     * @param callbackFuncation
     * @param loadingToast
     */
    const checkPayOrderIsSuccess = (outTradeNo, callbackFuncation, loadingToast) => {
        httpRequest({
            method: 'POST',
            url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "v2/vip/checkPayOrderIsSuccess",
            params: {outTradeNo: outTradeNo, nowTime: new Date().getTime()}
        }).then((response) => {
            loadingToast.close();
            if (response) {
                if (response.data.payOrderIsSuccess) {
                    //确保callback是一个函数
                    if (typeof callbackFuncation === "function") {
                        callbackFuncation();
                    }
                } else {
                    showConfirmDialog({
                        title: '支付校验未成功！',
                        message: '温馨提示：如你已完成支付，因网络延迟原因，可能会出现支付校验未成功的情况，此时可点击“再次验证”重新发起验证。如你未完成支付，可点击弹框外任意区域关闭弹框',
                        confirmButtonText: '再次验证',
                        cancelButtonText: '联系客服',
                        closeOnClickOverlay: true
                    }).then(() => {
                        // on confirm
                        checkPayOrderCommon(outTradeNo, callbackFuncation);
                    }).catch(() => {
                        // on cancel
                        routerContactCustomerService('replace');
                    })
                }
            }
        });
    }

    return {payCallBackCommonMethod, payCheckDialog, checkPayOrderCommon};
}
/**
 * 调用百度云接口上传视频文件
 */
export async function useBaiduCloudBosUpLoadVideo(videoFile, videoName) {
    const videoInfo = ref({});
    const resultData = await httpRequest({
        method: 'GET',
        url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "v2/video/getStsTempCredentials",
    })
    const client = new BosClient({});
    client.config = {
        endpoint: "https://bj.bcebos.com", //传入Bucket所在区域域名
        credentials: {
            ak: resultData.data.accessKeyId, //您的AccessKey
            sk: resultData.data.secretAccessKey //您的SecretAccessKey
        },
        sessionToken: resultData.data.sessionToken, // STS服务器下发的sessionToken
        removeVersionPrefix: true
    }
    videoInfo.value.videoSourceBucket = resultData.data.bosBucketZs;
    videoInfo.value.videoSourceKey = videoName;
    await client.putObjectFromBlob(videoInfo.value.videoSourceBucket, videoInfo.value.videoSourceKey, videoFile);
    const videoBosUrl = client.generatePresignedUrl(videoInfo.value.videoSourceBucket, videoInfo.value.videoSourceKey, Math.floor(Date.now() / 1000), -1);
    videoInfo.value.videoOriginalUrl = videoBosUrl.substring(0, videoBosUrl.indexOf('?'));

    return videoInfo;
}
/**
 * 获取微信分享卡片信息
 */
export function useGetWeChatShareInfo(routeObj) {
    const requestParam = ref({});
    requestParam.value.callBackUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + window.location.search;
    const routeParam = routeObj.fullPath.replace("?", "&").replace("/", "?routePath=");
    const shareParam = ref(routeObj.fullPath.replace(routeObj.path, "").replace("?", ""));
    requestParam.value.routeParam = encodeURIComponent(decodeURIComponent(routeParam));

    httpRequest({
        method: 'GET',
        url: globalConstant.CHUKAR_SERVER_ACCESS_DOMAIN_NAME + "weixin/getWeChatShareInfo",
        params: requestParam.value
    }).then((response) => {
        //拼接参数
        if (getUserInfoStore().checkUserLoginState()) {
            if (shareParam.value === "") {
                shareParam.value = "shareUserId=" + getUserInfoStore().userInfo.userId;
            } else if (shareParam.value.indexOf("shareUserId=") !== -1) {
                const reg = new RegExp("(^|&)shareUserId=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
                const paramObj = shareParam.value.match(reg); //匹配目标参数
                if (paramObj != null) {
                    const shareUserIdValue = decodeURIComponent(paramObj[2]);
                    shareParam.value = shareParam.value.replace("shareUserId=" + shareUserIdValue, "shareUserId=" + getUserInfoStore().userInfo.userId);
                }
            } else {
                shareParam.value += "&shareUserId=" + getUserInfoStore().userInfo.userId;
            }
        }

        const shareLink = response.data.shareLink + "?routeParam=" + routeObj.path.replace("/", "") + "!" + shareParam.value.replace(/=/g, ",").replace(/&/g, ";");

        const wechatShareInfo = {
            title: response.data.shareTitle, // 分享标题
            desc: response.data.describe, //分享描述
            link: shareLink, // 分享链接
            imgUrl: response.data.shareImgUrl // 分享图标
        };

        wxJsSdk.config({
            debug: false, //生产环境需要关闭debug模式
            appId: response.data.appId, //appId通过微信服务号后台查看
            timestamp: response.data.timestamp, //生成签名的时间戳
            nonceStr: response.data.nonceStr, //生成签名的随机字符串
            signature: response.data.signature, //签名
            jsApiList: [ //需要调用的JS接口列表
                'updateAppMessageShareData', //分享给好友
                'updateTimelineShareData', //分享到朋友圈
                'getLocation'
            ],
            openTagList: [
                'wx-open-launch-weapp'
            ]
        });

        wxJsSdk.ready(() => {
            //分享到朋友圈
            wxJsSdk.updateTimelineShareData(wechatShareInfo);
            //分享给朋友
            wxJsSdk.updateAppMessageShareData(wechatShareInfo);
        });

        wxJsSdk.error((res) => {
            console.log(res);
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
        });
    })
}