import {showConfirmDialog, showDialog, showImagePreview, showLoadingToast} from 'vant';
import {useRouterStore} from '@/stores/router.js';
import {useUserInfoStore} from '@/stores/userInfo.js';
import {isMobile, isWeixinBrowser} from '@/assets/js/commonUtil.js';
import {useOpenChukarVideoApp} from '@/assets/js/commonComposables.js';
import httpRequest from "@/httpRequest/httpRequest.js";
import globalConstant from "@/assets/js/globalConstant.js";
import {useCommonInfoStore} from '@/stores/commonInfoStore.js';
import {wechatAuthLogin} from "@/assets/js/login.js";
import {ref} from "vue";

const getRouterStore = () => {
    return useRouterStore();
}
const getUserInfoStore = () => {
    return useUserInfoStore();
}

const getCommonInfoStore = () => {
    return useCommonInfoStore();
}

/**
 * 用户不可用弹框
 */
export function userUnusableDialog() {
    showDialog({
        message: '当前登录状态已过期，请重新登录！',
    }).then(() => {
        // on close
        getUserInfoStore().clearUserInfo();
        toLogin();
    });
}
/*
登录或跳转下载页面
*/
export function toLogin() {
    if (getCommonInfoStore().accessSource.indexOf("app") > -1 || getCommonInfoStore().accessSource.indexOf("App") > -1) {
        if (getCommonInfoStore().accessSource === 'oldApp') {
            getRouterStore().forward({name: 'promptUpdate'});
        } else {
            webPageCall.toLogin();
        }
    } else if (getCommonInfoStore().accessSource === 'wxPublic') {
        wechatAuthLogin(getRouterStore().getCurrentRoute());
    } else if (getCommonInfoStore().accessSource === 'wxAppLet') {
        getRouterStore().forward({name: 'miniProgramDownLoad'});
    } else if (getCommonInfoStore().accessSource === 'webH5') {
        getRouterStore().forward({name: 'videoHomeLogin'});
    } else {
        useOpenChukarVideoApp().downLoadApp();
    }
}
/**
 * 跳转3D视频项目
 */
export function routerVideo3D() {
    getCommonInfoStore().setSystemLotteryValue(5);
    getRouterStore().forward({
        name: 'video3DTabHome'
    });
}
/**
 * 图片预览
 */
export function videoResultImagePreview(images, index) {
    let imageList = [];
    images.forEach(function (image) {
        imageList.push(image.origin);
    });
    showImagePreview({
        images: imageList,
        startPosition: index,
        closeable: true
    });
}
/**
 * 联系管理员弹框
 */
export function contactCustomerServiceDialog(message, routerType) {
    showConfirmDialog({
        title: '温馨提示！',
        message: message + '，请联系客服解决问题',
        confirmButtonText: '联系客服',
    }).then(() => {
        // on confirm
        routerContactCustomerService(routerType);
    }).catch(() => {
        // on cancel
        getRouterStore().back();
    })
}
/**
 * 跳转打赏页面
 */
export function routerRewardUser(userId, userName, videoId) {
    if (getUserInfoStore().checkUserLoginState()) {
        getRouterStore().forward({
            name: 'rewardUser',
            query: {
                userId: userId,
                userName: userName,
                videoId: videoId,
                pageVnk: new Date().getTime()
            }
        });
    } else {
        toLogin();
    }
}
/**
 * 跳转充值页面
 */
export function routerTopUpGoldCoins() {
    if (getUserInfoStore().checkUserLoginState()) {
        getRouterStore().forward({
            name: 'goldCoinsTopUp',
            query: {
                pageVnk: new Date().getTime()
            }
        });
    } else {
        toLogin();
    }
}
/**
 * 跳转抽奖页面
 */
export function routerWheelSurf(routeQuery) {
    if (getUserInfoStore().checkUserLoginState()) {
        getRouterStore().forward({
            name: 'wheelSurf',
            query: routeQuery
        });
    } else {
        toLogin();
    }
}
/**
 * 跳转代金券页面
 */
export function routerMyCoupons(routerType) {
    if (getUserInfoStore().checkUserLoginState()) {
        if (routerType === 'replace') {
            getRouterStore().replace({
                name: 'videoMyCoupons'
            });
        } else {
            getRouterStore().forward({
                name: 'videoMyCoupons'
            });
        }
    } else {
        toLogin();
    }
}
/**
 * 跳转联系客服页面
 */
export function routerContactCustomerService(routerType) {
    if (routerType === 'replace') {
        getRouterStore().replace({
            name: 'contactCustomerService'
        })
    } else {
        getRouterStore().forward({
            name: 'contactCustomerService'
        });
    }
}
/**
 * 跳转视频详情页面
 */
export function routerVideoDetails(routerType, videoId, videoData) {
    if (getCommonInfoStore().accessSource === 'videoApp') {
        webPageCall.toVideoDetail(videoId);
    } else {
        if (routerType === 'replace') {
            getRouterStore().replace({
                name: 'videoDetails',
                query: {
                    videoId: videoId,
                    videoDatas: videoData
                }
            })
        } else {
            getRouterStore().forward({
                name: 'videoDetails',
                query: {
                    videoId: videoId,
                    videoDatas: videoData
                }
            })
        }
    }
}
/**
 * 跳转讲师主页
 */
export function routerInstructorHome(userId, instructorType) {
    if (instructorType === 1) {
        getRouterStore().forward({
            name: 'instructorHome',
            query: {
                instructorUserId: userId
            }
        })
    }
}
/**
 * 跳转金牌讲师页
 */
export function routerInstructorMastreTeam(groupId, groupName) {
    getRouterStore().forward({
        name: 'instructorMastreTeam',
        query: {
            groupId: groupId,
            groupName: groupName
        }
    });
}
/**
 * 跳转讲师战绩页
 */
export function routerInstructorRecord(instructorUserId, lottery) {
    getRouterStore().forward({
        name: 'instructorRecord',
        query: {
            instructorUserId: instructorUserId,
            lottery: lottery
        }
    });
}
/**
 * 跳转视频购买页
 */
export function routerBuyVideoHome(videoId) {
    if (getUserInfoStore().checkUserLoginState()) {
        getRouterStore().forward({
            name: 'buyVideoHome',
            query: {
                videoId: videoId,
                pageVnk: new Date().getTime()
            }
        })
    } else {
        toLogin();
    }
}
/**
 * 跳转会员详情页
 */
export function routerVideoVipHome(roleId, buyMonthCount) {
    if (getUserInfoStore().checkUserLoginState()) {
        if (getCommonInfoStore().accessSource === 'videoApp') {
            webPageCall.goOpenVip(orderMonthCount, orderRoleId);
        } else {
            const vipTypeActive = ref(0);
            switch (buyMonthCount) {
                case 12 :
                    vipTypeActive.value = 0;
                    break;
                case 6 :
                    vipTypeActive.value = 1;
                    break;
                case 3 :
                    vipTypeActive.value = 2;
                    break;
                case 1 :
                    vipTypeActive.value = 3;
                    break;
            }
            getRouterStore().forward({
                name: 'videoVipHome',
                query: {
                    roleId: roleId,
                    vipTypeActive: vipTypeActive.value
                }
            })
        }
    } else {
        toLogin();
    }
}
/**
 * 跳转关注、粉丝页
 */
export function routerSocialGraph(userId, active, fansCount, followingCount) {
    getRouterStore().forward({
        name: 'followFans',
        query: {
            userId: userId,
            active: active,
            fansCount: fansCount,
            followingCount: followingCount
        }
    })
}
/**
 * 跳转邀请好友说明页
 */
export function routerMyInvitationCourse() {
    getRouterStore().forward({
        name: 'myInvitationCourse'
    })
}
/**
 * 跳转首页
 */
export function routerVideoTabHome() {
    getCommonInfoStore().setSystemLotteryValue(1);
    getRouterStore().forward({
        name: 'videoTabHome'
    })
}
/**
 * 跳转绑定手机号
 */
export function routerBindPhoneNumber() {
    getRouterStore().forward({
        name: 'bindPhoneNumber'
    })
}
/**
 * 跳转我的消息
 */
export function routerMyMessage() {
    getRouterStore().forward({
        name: 'videoMyMessage'
    })
}
/**
 * 获取访问客户端标识
 */
export function returnAccessSource() {
    let accessSource = "";
    if (getCommonInfoStore().accessSource === 'wxPublic') {
        accessSource = 'weixin_zgcx_public_number';
    } else if (getCommonInfoStore().accessSource === 'wxAppLet') {
        if (getCommonInfoStore().accessSourceClient === 'wx6e167ef509d715ff') {
            accessSource = 'weixin_zgjt_helper_miniProgram';
        } else {
            accessSource = 'weixin_zg_video_helper_miniProgram';
        }
    } else if (getCommonInfoStore().accessSource === 'webH5') {
        if (isMobile()) {
            accessSource = 'webH5';
        } else {
            accessSource = 'videoPc';
        }
    } else if (getCommonInfoStore().accessSource === 'videoApp') {
        accessSource = 'videoApp';
    }
    return accessSource;
}
/**
 * 打开加载样式
 */
export function openLoadingToast(message) {
    return showLoadingToast({
        message: message,
        forbidClick: true,
        duration: 0
    });
}