export default {
    /* 请求鹧鸪讲堂后端服务器域名-测试 */
    //CHUKAR_SERVER_ACCESS_DOMAIN_NAME: '//testbe.zhegu8.xyz/',
    /* 请求鹧鸪讲堂后端服务器域名-本地 */
    //CHUKAR_SERVER_ACCESS_DOMAIN_NAME: '//192.168.0.106/',
    /* 请求鹧鸪讲堂后端服务器域名 */
    CHUKAR_SERVER_ACCESS_DOMAIN_NAME: '//be.zhegu8.xyz/',
    /* 请求鹧鸪讲堂后端服务器域名-备用 */
    CHUKAR_SERVER_ACCESS_STANDBY_DOMAIN_NAME: '//videodata.gouli168.cn/',
    /* 请求够力后端服务器域名 */
    GAOLAT_SERVER_ACCESS_DOMAIN_NAME: '//wsqdata.gouli8.cn/',
    /* 鹧鸪讲堂静态图片资源路径 */
    CHUKAR_STATIC_FILE_ACCESS_URL: '../',
    /* 够力静态图片资源路径 */
    GAOLAT_STATIC_FILE_ACCESS_URL: "//mstatic.gouli168.cn/gaolatmob/",
    /* 系统版本号 */
    CHUKAR_WEB_MOBILE_VERSION: 'chukar_web_mobile_version',
    /* 空内容图片 */
    EMPTY_DATA_IMAGE: '../static/images/no-data.png',
    /* 微信访问够力网络域名 */
    GAOLAT_DOMAIN_WECHAT: '//66.bolo8.top/',
    /* 浏览器访问够力网络域名 */
    GAOLAT_DOMAIN_BROWSER: '//m.gouli99.cn/',
    /* 一小时毫秒数 */
    ONE_HOURS_MILLISECONDS: 3600000,
    /* 俩小时毫秒数 */
    TWO_HOURS_MILLISECONDS: 7200000,
    /* 六小时毫秒数 */
    SIX_HOURS_MILLISECONDS: 21600000,
    /* 三天毫秒数 */
    THREE_DAYS_MILLISECONDS: 259200000,
    /* 关注图片 */
    USER_FOLLOW_IMAGE: {
        0: '../static/images/jgzpc.png',
        1: '../static/images/ygzpc.png',
        2: '../static/images/jgzpc.png',
        3: '../static/images/xhgz.png'
    },
    /* 金牌讲师标识图片 */
    JP_INSTRUCTOR_FLAG_IMG: '../static/images/video_user_jpflag1.png',
    /* 试用期讲师标识图片 */
    SYQ_INSTRUCTOR_FLAG_IMG: '../static/images/zgjt_syq_icon5.png',
    /* 代金券背景色 */
    couponBackground: ['#FA4E57', '#82B3DE', '#F1A806', '#FB8284', '#CA8CED', '#E38349'],
    /* 支付类型 */
    PAY_TYPE: {
        weChatPay: 'weChatPay',
        aliPay: 'aliPay'
    },
    /* 彩种类型 */
    LOTTERY_TYPE: {
        1: '七星彩',
        2: '排列五',
        5: '福彩3D'
    },
    /* 性别 */
    GENDER_TYPE: {
        0: '女',
        1: '男',
        2: '未知'
    },
    /*省-市数据列表*/
    area_list: [
        {
            city: '北京',
            city_list: [
                {city: '北京'}
            ]
        },
        {
            city: '天津',
            city_list: [
                {city: '天津'}
            ]
        },
        {
            city: '重庆',
            city_list: [
                {city: '重庆'}
            ]
        },
        {
            city: '上海',
            city_list: [
                {city: '上海'}
            ]
        },
        {
            city: '河北',
            city_list: [
                {city: '石家庄'},
                {city: '唐山'},
                {city: '秦皇岛'},
                {city: '邯郸'},
                {city: '邢台'},
                {city: '保定'},
                {city: '张家口'},
                {city: '承德'},
                {city: '沧州'},
                {city: '廊坊'},
                {city: '衡水'}
            ]
        },
        {
            city: '山西',
            city_list: [
                {city: '太原'},
                {city: '大同'},
                {city: '阳泉'},
                {city: '长治'},
                {city: '晋城'},
                {city: '朔州'},
                {city: '晋中'},
                {city: '运城'},
                {city: '忻州'},
                {city: '吕梁'},
                {city: '临汾'}
            ]
        },
        {
            city: '内蒙古',
            city_list: [
                {city: '呼和浩特'},
                {city: '包头'},
                {city: '乌海'},
                {city: '赤峰'},
                {city: '通辽'},
                {city: '鄂尔多斯'},
                {city: '呼伦贝尔'},
                {city: '巴彦淖尔'},
                {city: '乌兰察布'},
                {city: '兴安盟'},
                {city: '锡林郭勒盟'},
                {city: '阿拉善盟'}
            ]
        },
        {
            city: '辽宁',
            city_list: [
                {city: '沈阳'},
                {city: '大连'},
                {city: '鞍山'},
                {city: '抚顺'},
                {city: '本溪'},
                {city: '丹东'},
                {city: '锦州'},
                {city: '营口'},
                {city: '阜新'},
                {city: '辽阳'},
                {city: '盘锦'},
                {city: '铁岭'},
                {city: '朝阳'},
                {city: '葫芦岛'}
            ]
        },
        {
            city: '吉林',
            city_list: [
                {city: '长春'},
                {city: '吉林'},
                {city: '四平'},
                {city: '辽源'},
                {city: '通化'},
                {city: '白山'},
                {city: '松原'},
                {city: '白城'},
                {city: '延边'}
            ]
        },
        {
            city: '黑龙江',
            city_list: [
                {city: '哈尔滨'},
                {city: '齐齐哈尔'},
                {city: '鸡西'},
                {city: '鹤岗'},
                {city: '双鸭山'},
                {city: '大庆'},
                {city: '伊春'},
                {city: '佳木斯'},
                {city: '七台河'},
                {city: '牡丹江'},
                {city: '黑河'},
                {city: '绥化'},
                {city: '大兴安岭地区'}
            ]
        },
        {
            city: '江苏',
            city_list: [
                {city: '南京'},
                {city: '无锡'},
                {city: '徐州'},
                {city: '常州'},
                {city: '苏州'},
                {city: '南通'},
                {city: '连云港'},
                {city: '淮安'},
                {city: '盐城'},
                {city: '扬州'},
                {city: '镇江'},
                {city: '泰州'},
                {city: '宿迁'}
            ]
        },
        {
            city: '浙江',
            city_list: [
                {city: '杭州'},
                {city: '宁波'},
                {city: '温州'},
                {city: '嘉兴'},
                {city: '湖州'},
                {city: '绍兴'},
                {city: '金华'},
                {city: '衢州'},
                {city: '舟山'},
                {city: '台州'},
                {city: '丽水'}
            ]
        },
        {
            city: '安徽',
            city_list: [
                {city: '合肥'},
                {city: '芜湖'},
                {city: '蚌埠'},
                {city: '淮南'},
                {city: '马鞍山'},
                {city: '淮北'},
                {city: '铜陵'},
                {city: '安庆'},
                {city: '黄山'},
                {city: '滁州'},
                {city: '阜阳'},
                {city: '宿州'},
                {city: '巢湖'},
                {city: '六安'},
                {city: '亳州'},
                {city: '池州'},
                {city: '宣城'}
            ]
        },
        {
            city: '安徽',
            city_list: [
                {city: '合肥'},
                {city: '芜湖'},
                {city: '蚌埠'},
                {city: '淮南'},
                {city: '马鞍山'},
                {city: '淮北'},
                {city: '铜陵'},
                {city: '安庆'},
                {city: '黄山'},
                {city: '滁州'},
                {city: '阜阳'},
                {city: '宿州'},
                {city: '巢湖'},
                {city: '六安'},
                {city: '亳州'},
                {city: '池州'},
                {city: '宣城'}
            ]
        },
        {
            city: '福建',
            city_list: [
                {city: '福州'},
                {city: '厦门'},
                {city: '莆田'},
                {city: '三明'},
                {city: '泉州'},
                {city: '漳州'},
                {city: '南平'},
                {city: '龙岩'},
                {city: '宁德'}
            ]
        },
        {
            city: '江西',
            city_list: [
                {city: '南昌'},
                {city: '景德镇'},
                {city: '萍乡'},
                {city: '九江'},
                {city: '新余'},
                {city: '鹰潭'},
                {city: '赣州'},
                {city: '吉安'},
                {city: '宜春'},
                {city: '抚州'},
                {city: '上饶'}
            ]
        },
        {
            city: '山东',
            city_list: [
                {city: '济南'},
                {city: '青岛'},
                {city: '淄博'},
                {city: '枣庄'},
                {city: '东营'},
                {city: '烟台'},
                {city: '潍坊'},
                {city: '济宁'},
                {city: '泰安'},
                {city: '威海'},
                {city: '日照'},
                {city: '临沂'},
                {city: '德州'},
                {city: '聊城'},
                {city: '滨州'},
                {city: '菏泽'},
                {city: '莱芜'}
            ]
        },
        {
            city: '河南',
            city_list: [
                {city: '郑州'},
                {city: '开封'},
                {city: '洛阳'},
                {city: '平顶山'},
                {city: '安阳'},
                {city: '鹤壁'},
                {city: '新乡'},
                {city: '焦作'},
                {city: '濮阳'},
                {city: '许昌'},
                {city: '漯河'},
                {city: '三门峡'},
                {city: '南阳'},
                {city: '商丘'},
                {city: '信阳'},
                {city: '周口'},
                {city: '驻马店'}
            ]
        },
        {
            city: '湖北',
            city_list: [
                {city: '武汉'},
                {city: '黄石'},
                {city: '十堰'},
                {city: '宜昌'},
                {city: '襄阳'},
                {city: '鄂州'},
                {city: '荆门'},
                {city: '孝感'},
                {city: '荆州'},
                {city: '黄冈'},
                {city: '咸宁'},
                {city: '随州'},
                {city: '恩施'}
            ]
        },
        {
            city: '湖南',
            city_list: [
                {city: '长沙'},
                {city: '株洲'},
                {city: '湘潭'},
                {city: '衡阳'},
                {city: '邵阳'},
                {city: '岳阳'},
                {city: '常德'},
                {city: '张家界'},
                {city: '益阳'},
                {city: '郴州'},
                {city: '永州'},
                {city: '怀化'},
                {city: '娄底'},
                {city: '湘西土家族苗族自治州'}
            ]
        },
        {
            city: '广东',
            city_list: [
                {city: '广州'},
                {city: '韶关'},
                {city: '深圳'},
                {city: '珠海'},
                {city: '汕头'},
                {city: '佛山'},
                {city: '江门'},
                {city: '湛江'},
                {city: '茂名'},
                {city: '肇庆'},
                {city: '惠州'},
                {city: '梅州'},
                {city: '汕尾'},
                {city: '河源'},
                {city: '阳江'},
                {city: '清远'},
                {city: '东莞'},
                {city: '中山'},
                {city: '潮州'},
                {city: '揭阳'},
                {city: '云浮'}
            ]
        },
        {
            city: '广西',
            city_list: [
                {city: '南宁'},
                {city: '柳州'},
                {city: '桂林'},
                {city: '梧州'},
                {city: '北海'},
                {city: '防城港'},
                {city: '钦州'},
                {city: '贵港'},
                {city: '玉林'},
                {city: '百色'},
                {city: '贺州'},
                {city: '河池'},
                {city: '来宾'},
                {city: '崇左'}
            ]
        },
        {
            city: '海南',
            city_list: [
                {city: '海口'},
                {city: '三亚'},
                {city: '三沙'},
                {city: '儋州'},
                {city: '万宁'},
                {city: '五指山'},
                {city: '文昌'},
                {city: '琼海'},
                {city: '东方'},
                {city: '定安'},
                {city: '屯昌'},
                {city: '澄迈'},
                {city: '临高'},
                {city: '白沙黎族自治县'},
                {city: '昌江黎族自治县'},
                {city: '乐东黎族自治县'},
                {city: '陵水黎族自治县'},
                {city: '保亭黎族苗族自治县'},
                {city: '琼中黎族苗族自治县'}
            ]
        },
        {
            city: '四川',
            city_list: [
                {city: '成都'},
                {city: '自贡'},
                {city: '攀枝花'},
                {city: '泸州'},
                {city: '德阳'},
                {city: '绵阳'},
                {city: '广元'},
                {city: '遂宁'},
                {city: '内江'},
                {city: '乐山'},
                {city: '南充'},
                {city: '眉山'},
                {city: '宜宾'},
                {city: '广安'},
                {city: '达州'},
                {city: '雅安'},
                {city: '巴中'},
                {city: '资阳'},
                {city: '阿坝藏族羌族自治州'},
                {city: '甘孜藏族自治州'},
                {city: '凉山彝族自治州'}
            ]
        },
        {
            city: '贵州',
            city_list: [
                {city: '贵阳'},
                {city: '六盘水'},
                {city: '遵义'},
                {city: '安顺'},
                {city: '毕节'},
                {city: '铜仁'},
                {city: '黔西南布依族苗族自治州'},
                {city: '黔东南苗族侗族自治州'},
                {city: '黔南布依族苗族自治州'}
            ]
        },
        {
            city: '云南',
            city_list: [
                {city: '昆明'},
                {city: '曲靖'},
                {city: '玉溪'},
                {city: '保山'},
                {city: '昭通'},
                {city: '丽江'},
                {city: '普洱'},
                {city: '临沧'},
                {city: '楚雄彝族自治州'},
                {city: '红河哈尼族彝族自治州'},
                {city: '文山壮族苗族自治州'},
                {city: '西双版纳傣族自治州'},
                {city: '大理白族自治州'},
                {city: '德宏傣族景颇族自治州'},
                {city: '怒江傈僳族自治州'},
                {city: '迪庆藏族自治州'}
            ]
        },
        {
            city: '西藏',
            city_list: [
                {city: '拉萨'},
                {city: '日喀则'},
                {city: '昌都'},
                {city: '林芝'},
                {city: '山南'},
                {city: '那曲'},
                {city: '阿里地区'}
            ]
        },
        {
            city: '陕西',
            city_list: [
                {city: '西安'},
                {city: '铜川'},
                {city: '宝鸡'},
                {city: '咸阳'},
                {city: '渭南'},
                {city: '延安'},
                {city: '汉中'},
                {city: '榆林'},
                {city: '安康'},
                {city: '商洛'}
            ]
        },
        {
            city: '甘肃',
            city_list: [
                {city: '兰州'},
                {city: '嘉峪关'},
                {city: '金昌'},
                {city: '白银'},
                {city: '天水'},
                {city: '武威'},
                {city: '张掖'},
                {city: '平凉'},
                {city: '酒泉'},
                {city: '庆阳'},
                {city: '定西'},
                {city: '陇南'},
                {city: '临夏回族自治州'},
                {city: '甘南藏族自治州'}
            ]
        },
        {
            city: '青海',
            city_list: [
                {city: '西宁'},
                {city: '海东'},
                {city: '海北藏族自治州'},
                {city: '黄南藏族自治州'},
                {city: '海南藏族自治州'},
                {city: '果洛藏族自治州'},
                {city: '玉树藏族自治州'},
                {city: '海西蒙古族藏族自治州'}
            ]
        },
        {
            city: '宁夏',
            city_list: [
                {city: '银川'},
                {city: '石嘴山'},
                {city: '吴忠'},
                {city: '固原'},
                {city: '中卫'}
            ]
        },
        {
            city: '新疆',
            city_list: [
                {city: '乌鲁木齐'},
                {city: '克拉玛依'},
                {city: '吐鲁番'},
                {city: '哈密'},
                {city: '昌吉回族自治州'},
                {city: '博尔塔拉蒙古自治州'},
                {city: '巴音郭楞蒙古自治州'},
                {city: '阿克苏地区'},
                {city: '克孜勒苏柯尔克孜自治州'},
                {city: '喀什地区'},
                {city: '和田地区'},
                {city: '伊犁哈萨克自治州'},
                {city: '塔城地区'},
                {city: '阿勒泰地区'}
            ]
        },
        {
            city: '台湾',
            city_list: [
                {city: '台北'},
                {city: '高雄'},
                {city: '台南'},
                {city: '台中'},
                {city: '金门'},
                {city: '南投'},
                {city: '基隆'},
                {city: '新竹'},
                {city: '嘉义'},
                {city: '新北'},
                {city: '宜兰'},
                {city: '新竹'},
                {city: '桃园'},
                {city: '苗栗'},
                {city: '彰化'},
                {city: '嘉义'},
                {city: '云林'},
                {city: '屏东'},
                {city: '台东'},
                {city: '花莲'},
                {city: '澎湖'},
                {city: '连江'}
            ]
        },
        {
            city: '香港',
            city_list: [
                {city: '香港岛'},
                {city: '九龙'},
                {city: '新界'}
            ]
        },
        {
            city: '澳门',
            city_list: [
                {city: '澳门半岛'},
                {city: '离岛'}
            ]
        }
    ]
}
